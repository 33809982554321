
import {Component, Vue, Watch, Model} from 'vue-property-decorator';

@Component({
  name: 'radioSelect',
})
export default class extends Vue {
  @Model('input') value: string;
  private radio = '';
  private radioData: Array<string> = [];
  created() {
    const radioData = [] as Array<string>;
    if (this.$store.state.mallServices) {
      radioData.push('商城');
    }
    if (this.$store.state.usedServices) {
      radioData.push('二手');
    }
    this.radioData = radioData;
    if (this.value) {
      this.radio = this.value;
    } else {
      if (this.$store.state.mallServices) {
        this.radio = '商城';
      } else {
        this.radio = '二手';
      }
    }
  }
  @Watch('radio')
  changeColor(val: string) {
    this.$emit('input', val);
  }
}
