
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {regComp} from '../../../comp';
import color from '../../operate/color/index.vue';
import navigateTo from '../../operate/navigateTo/index.vue';
import orderTab from '../../operate/orderTab/index.vue';
import mallServices from '../../operate/mallServices/index.vue';
import usedServices from '../../operate/usedServices/index.vue';
import agentServices from '../../operate/agentServices/index.vue';

interface MenuData {
  name?: string;
  status?: string;
  type?: string;
}
@Component({
  name: 'orderList',
  components: {
    color,
    navigateTo,
    orderTab,
    mallServices,
    usedServices,
    agentServices,
  },
})
export default class orderList extends Vue {
  static metadata = {
    name: '订单列表',
    value: 'orderList',
    type: '订单',
    icon: 'bill',
    schema: {
      type: 'object',
      properties: {
        setting: {
          type: 'object',
          'ui:options': {
            onlyShowIfDependent: true,
          },
          properties: {
            mallDetailUrl: {
              title: '商城详情',
              type: 'string',
              'ui:widget': navigateTo,
            },
            agentDetailUrl: {
              title: '跑腿详情',
              type: 'string',
              'ui:widget': navigateTo,
            },
            marketDetailUrl: {
              title: '二手详情',
              type: 'string',
              'ui:widget': navigateTo,
            },
            rechargeDetailUrl: {
              title: '充值详情',
              type: 'string',
              'ui:widget': navigateTo,
            },
            tab: {
              title: '订单导航',
              type: 'array',
              minItems: 1,
              maxItems: 8,
              items: {
                type: 'object',
                'ui:field': orderTab,
              },
            },
            style: {
              type: 'object',
              properties: {
                background: {
                  title: '背景色',
                  type: 'string',
                  'ui:widget': color,
                  default: '#ffffff',
                },
              },
            },
            mallDetailUrlShow: {
              title: '',
              type: 'string',
              'ui:widget': mallServices,
            },
            agentDetailUrlShow: {
              title: '',
              type: 'string',
              'ui:widget': agentServices,
            },
            marketDetailUrlShow: {
              title: '',
              type: 'string',
              'ui:widget': usedServices,
            },
          },
          dependencies: {
            mallDetailUrlShow: ['mallDetailUrl'],
            agentDetailUrlShow: ['agentDetailUrl'],
            marketDetailUrlShow: ['marketDetailUrl'],
          },
        },
      },
    },
    uiSchema: {},
    paramData: [],
  };
  @Prop()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setting: any;
  @Prop()
  private index: Array<number>;
  @Prop()
  private borderIndex: number;
  private menuData: Array<MenuData> = [];
  private menuIndex = 0;
  private styles: {
    background: string;
  } = {background: ''};

  @Watch('setting', {deep: true, immediate: true})
  changeSetting(val: {
    style?: {
      background: string;
    };
    tab?: Array<MenuData>;
  }) {
    if (val.style) {
      if (val.style.background) {
        this.styles.background = val.style.background;
      }
    }
    const menuData = [] as Array<MenuData>;
    if (val.tab) {
      val.tab.forEach(item => {
        if (item.name) {
          menuData.push(item);
        }
      });
      this.menuData = menuData;
    }
  }
  //删除组件
  private delComp() {
    this.$emit('delCompPopUps', this.index[0], this.index[1]);
  }
}

regComp({orderList});
