
import {Component, Vue, Prop} from 'vue-property-decorator';
import {regComp} from '../../../comp';
import navigateTo from '../../operate/navigateTo/index.vue';

@Component({
  name: 'businessInfo',
  components: {navigateTo},
})
export default class businessInfo extends Vue {
  static metadata = {
    name: '商品列表',
    value: 'businessInfo',
    type: '商城',
    icon: 'bill',
    schema: {
      type: 'object',
      properties: {
        setting: {
          type: 'object',
          properties: {
            navigateTo: {
              title: '跳转地址',
              type: 'string',
              'ui:widget': navigateTo,
            },
            goodsUrl: {
              title: '商品跳转地址',
              type: 'string',
              'ui:widget': navigateTo,
            },
            memberUrl: {
              title: '会员跳转地址',
              type: 'string',
              'ui:widget': navigateTo,
            },
          },
        },
      },
    },
    uiSchema: {},
    paramData: [
      {name: 'shopId', des: '店铺名称', type: 'ObjectId', dataType: '店铺'},
    ],
  };
  @Prop()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setting: any;
  @Prop()
  private index: Array<number>;
  @Prop()
  private borderIndex: number;
  private menuData: Array<{name: string}> = [
    {name: '点餐'},
    {name: '评价'},
    {name: '商家'},
  ];
  private menuIndex = 0;
  private sortData = ['黄焖鸡米饭', '黄焖鸡土豆饭', '黄焖鸡配菜'];
  //删除组件
  private delComp() {
    this.$emit('delCompPopUps', this.index[0], this.index[1]);
  }
}

regComp({businessInfo});
