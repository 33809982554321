
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {regComp} from '../../../comp';
import color from '../../operate/color/index.vue';
import numberText from '../../operate/numberText/index.vue';
import navigateTo from '../../operate/navigateTo/index.vue';
import uploadImg from '../../operate/uploadImg/index.vue';
import imgLoad from '../../operate/imgLoad/index.vue';

@Component({
  name: 'competitionResult',
  components: {numberText, color, navigateTo, uploadImg, imgLoad},
})
export default class competitionResult extends Vue {
  static metadata = {
    name: '竞赛结果',
    value: 'competitionResult',
    type: '问卷',
    icon: 'bill',
    schema: {
      type: 'object',
      properties: {
        setting: {
          type: 'object',
          properties: {
            backHomeUrl: {
              title: '返回首页链接',
              type: 'string',
              'ui:widget': navigateTo,
            },
            againUrl: {
              title: '再测一次链接',
              type: 'string',
              'ui:widget': navigateTo,
            },
            resultPassedImg: {
              title: '通过测试图片',
              type: 'string',
              'ui:widget': uploadImg,
              default: '612cbd84585582b43e75d921',
            },
            resultNotPassedImg: {
              title: '未通过测试图片',
              type: 'string',
              'ui:widget': uploadImg,
            },
            style: {
              type: 'object',
              properties: {
                background: {
                  title: '背景色',
                  type: 'string',
                  'ui:widget': color,
                  default: '#ffffff',
                },
                padding: {
                  title: '页面边距',
                  type: 'array',
                  minItems: 4,
                  maxItems: 4,
                  items: {
                    type: 'number',
                    'ui:field': numberText,
                  },
                  default: [0, 0, 0, 0],
                },
                borderRadius: {
                  title: '圆角',
                  type: 'array',
                  minItems: 4,
                  maxItems: 4,
                  items: {
                    type: 'number',
                    'ui:field': numberText,
                  },
                  default: [0, 0, 0, 0],
                },
              },
            },
          },
        },
      },
    },
    uiSchema: {
      setting: {
        style: {
          padding: {
            'ui:options': {
              addable: false,
              sortable: false,
              removable: false,
              showIndexNumber: false,
            },
          },
          borderRadius: {
            'ui:options': {
              addable: false,
              sortable: false,
              removable: false,
              showIndexNumber: false,
            },
          },
        },
      },
    },
    paramData: [],
  };
  @Prop()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setting: any;
  @Prop()
  private index: Array<number>;
  @Prop()
  private borderIndex: number;
  private styles: {
    background: string;
    padding: string;
    borderRadius: string;
  } = {background: '', padding: '', borderRadius: ''};
  private resultPassedImg = '';

  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  @Watch('setting', {deep: true, immediate: true})
  changeSetting(val: {
    style: {
      background: string;
      padding: Array<number>;
      borderRadius: Array<number>;
    };
    resultPassedImg: string;
  }) {
    if (val.resultPassedImg) {
      this.resultPassedImg = val.resultPassedImg;
    }
    if (val.style) {
      if (val.style.background) {
        this.styles.background = val.style.background;
      }
      this.styles.padding =
        val.style.padding[0] +
        'px ' +
        val.style.padding[1] +
        'px ' +
        val.style.padding[2] +
        'px ' +
        val.style.padding[3] +
        'px';
      this.styles.borderRadius =
        val.style.borderRadius[0] +
        'px ' +
        val.style.borderRadius[1] +
        'px ' +
        val.style.borderRadius[2] +
        'px ' +
        val.style.borderRadius[3] +
        'px';
    }
  }
  //删除组件
  private delComp() {
    this.$emit('delCompPopUps', this.index[0], this.index[1]);
  }
}

regComp({competitionResult});
