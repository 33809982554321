
import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
  name: 'switchList',
})
export default class extends Vue {
  @Prop()
  private list: Array<{
    name: string;
    text: string;
    selected: boolean;
  }>;
}
