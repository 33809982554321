
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import numberText from '../../operate/numberText/index.vue';
import {regComp} from '../../../comp';
import color from '../../operate/color/index.vue';
import uploadImg from '../../operate/uploadImg/index.vue';
import imgLoad from '../../operate/imgLoad/index.vue';

@Component({
  name: 'tipBanner',
  components: {numberText, color, uploadImg, imgLoad},
})
export default class tipBanner extends Vue {
  static metadata = {
    name: '警告',
    value: 'tipBanner',
    type: '基础',
    icon: 'bill',
    schema: {
      type: 'object',
      properties: {
        setting: {
          type: 'object',
          properties: {
            icon: {
              title: '图标',
              type: 'string',
              'ui:widget': uploadImg,
            },
            text: {
              type: 'string',
              title: '文字内容',
              default: '文字内容',
            },
            closed: {
              type: 'boolean',
              title: '是否可关闭',
              default: true,
            },
            style: {
              type: 'object',
              properties: {
                background: {
                  title: '背景色',
                  type: 'string',
                  'ui:widget': color,
                  default: '#FFFBE8',
                },
                color: {
                  title: '字体颜色',
                  type: 'string',
                  'ui:widget': color,
                  default: '#ED6A0C',
                },
              },
            },
          },
        },
      },
    },
    uiSchema: {},
    paramData: [],
  };
  @Prop()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setting: any;
  @Prop()
  private index: Array<number>;
  @Prop()
  private borderIndex: number;
  private styles: {
    background: string;
    color: string;
  } = {background: '', color: ''};
  private text = '文字内容';
  private closed = true;
  private icon = '';

  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  @Watch('setting', {deep: true, immediate: true})
  changeSetting(val: {
    icon: string;
    text: string;
    closed: boolean;
    style: {
      background: string;
      color: string;
    };
  }) {
    if (val.closed !== undefined) {
      this.closed = val.closed;
    }
    if (val.text) {
      this.text = val.text;
    }
    if (val.icon) {
      this.icon = this.downloadUrl + val.icon;
    }
    if (val.style) {
      if (val.style.background) {
        this.styles.background = val.style.background;
      }
      if (val.style.color) {
        this.styles.color = val.style.color;
      }
    }
  }
  //删除组件
  private delComp() {
    this.$emit('delCompPopUps', this.index[0], this.index[1]);
  }
}

regComp({tipBanner});
