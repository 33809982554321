
import {Component, Vue, Watch, Model} from 'vue-property-decorator';
import {usedProductLabels} from '../../../../../../../resources';
import {ObjectId} from 'bson';

@Component({
  name: 'usedSelectLabels',
})
export default class extends Vue {
  @Model('input') value: string;
  private label: Array<string> = [];
  private labelList: Array<string> = [];
  async created() {
    const list = await usedProductLabels.find(stage =>
      stage.$match(match => {
        match(
          f => f('spec')('applicationId'),
          e =>
            e.$eq(
              ObjectId.createFromHexString(this.$route.params.applicationId),
            ),
        )(
          f => f('spec')('name'),
          e => e.$eq('分类'),
        );
        return match;
      }),
    );
    if (list.length > 0) {
      this.labelList = list[0].spec.values;
      this.labelList.unshift('全部');
    }

    if (this.value) {
      this.label = JSON.parse(this.value);
    }
  }
  @Watch('label')
  changeLabel(val: string) {
    this.$emit('input', JSON.stringify(val));
  }
}
