
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
@Component({
  name: 'Submenu',
})
export default class extends Vue {
  @Prop()
  private submenu: Array<{
    top: string;
    list: Array<{
      name: string;
      active: boolean;
      close?: boolean;
    }>;
  }>;
  private menuActive = '';
  private showList = true;
  created() {
    if (this.$route.query.menu) {
      this.menuActive = (this.$route.query.menu as string) ?? '';
    } else {
      if (this.submenu.length > 0) {
        this.menuActive = this.submenu[0].list[0].name;
      }
    }
  }
  @Watch('submenu', {deep: true})
  private changeSubmenu() {
    if (!this.$route.query.menu) {
      this.menuActive = this.submenu[0].list[0].name;
    }
  }
  @Watch('$route.query', {deep: true})
  private changeRouteQuery() {
    if (this.$route.query.menu) {
      this.menuActive = (this.$route.query.menu as string) ?? '';
    } else {
      this.menuActive = this.submenu[0].list[0].name;
    }
  }
  //删除菜单
  private closeMenu(index: number, index1: number) {
    this.$emit('delPopUps', this.submenu[index].list[index1].name);
  }
  //切换菜单
  private changemenu(index: number, index1: number) {
    this.submenu.forEach(item => {
      item.list.forEach(item1 => {
        item1.active = false;
      });
    });
    this.submenu[index].list[index1].active = true;
    // this.$emit(
    //   'changeMenu',
    //   this.submenu[index].top + ':' + this.submenu[index].list[index1].name,
    // );
    if (this.submenu[index].list[index1].name !== this.$route.query.menu) {
      this.$router.push({
        query: {menu: this.submenu[index].list[index1].name},
      });
    }
  }
}
