
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {regComp} from '../../../comp';
import color from '../../operate/color/index.vue';
import numberText from '../../operate/numberText/index.vue';
import navigateTo from '../../operate/navigateTo/index.vue';
import radioSelect from '../../operate/radioSelect/index.vue';
import mallServices from '../../operate/mallServices/index.vue';
import usedServices from '../../operate/usedServices/index.vue';
import uploadImg from '../../operate/uploadImg/index.vue';
import imgLoad from '../../operate/imgLoad/index.vue';

@Component({
  name: 'pickUpPoint',
  components: {
    numberText,
    color,
    navigateTo,
    radioSelect,
    mallServices,
    usedServices,
    uploadImg,
    imgLoad,
  },
})
export default class pickUpPoint extends Vue {
  static metadata = {
    name: '选择点位',
    value: 'pickUpPoint',
    type: '我的',
    icon: 'bill',
    schema: {
      type: 'object',
      properties: {
        setting: {
          type: 'object',
          'ui:options': {
            onlyShowIfDependent: true,
          },
          properties: {
            navigateTo: {
              title: '跳转地址',
              type: 'string',
              'ui:widget': navigateTo,
            },
            currentPointImg: {
              title: '选中点位图片',
              type: 'string',
              'ui:widget': uploadImg,
              default: '611f45de3e2975685094f5dc',
            },
            nearbyPointImg: {
              title: '附近点位图片',
              type: 'string',
              'ui:widget': uploadImg,
              default: '611f45a33e2975685094f5da',
            },
            style: {
              type: 'object',
              properties: {
                background: {
                  title: '背景色',
                  type: 'string',
                  'ui:widget': color,
                  default: '#ffffff',
                },
                padding: {
                  title: '页面边距',
                  type: 'array',
                  minItems: 4,
                  maxItems: 4,
                  items: {
                    type: 'number',
                    'ui:field': numberText,
                  },
                  default: [0, 0, 0, 0],
                },
                borderRadius: {
                  title: '圆角',
                  type: 'array',
                  minItems: 4,
                  maxItems: 4,
                  items: {
                    type: 'number',
                    'ui:field': numberText,
                  },
                  default: [0, 0, 0, 0],
                },
              },
            },
            mallServices: {
              title: '',
              type: 'string',
              'ui:widget': mallServices,
            },
            usedServices: {
              title: '',
              type: 'string',
              'ui:widget': usedServices,
            },
          },
          dependencies: {
            mallServices: ['orderTypeDifferentiation'],
            usedServices: ['orderTypeDifferentiation'],
          },
        },
      },
    },
    uiSchema: {
      setting: {
        style: {
          padding: {
            'ui:options': {
              addable: false,
              sortable: false,
              removable: false,
              showIndexNumber: false,
            },
          },
          borderRadius: {
            'ui:options': {
              addable: false,
              sortable: false,
              removable: false,
              showIndexNumber: false,
            },
          },
        },
      },
    },
    paramData: [],
  };
  @Prop()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setting: any;
  @Prop()
  private index: Array<number>;
  @Prop()
  private borderIndex: number;
  private styles: {
    background: string;
    padding: string;
    borderRadius: string;
  } = {background: '', padding: '', borderRadius: ''};
  private wordData: Array<string> = [];
  private currentPointImg = '';
  private nearbyPointImg = '';

  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  @Watch('setting', {deep: true, immediate: true})
  changeSetting(val: {
    style: {
      background: string;
      padding: Array<number>;
      borderRadius: Array<number>;
    };
    currentPointImg: string;
    nearbyPointImg: string;
  }) {
    if (val.currentPointImg) {
      this.currentPointImg = val.currentPointImg;
    }
    if (val.nearbyPointImg) {
      this.nearbyPointImg = val.nearbyPointImg;
    }
    if (val.style) {
      if (val.style.background) {
        this.styles.background = val.style.background;
      }
      this.styles.padding =
        val.style.padding[0] +
        'px ' +
        val.style.padding[1] +
        'px ' +
        val.style.padding[2] +
        'px ' +
        val.style.padding[3] +
        'px';
      this.styles.borderRadius =
        val.style.borderRadius[0] +
        'px ' +
        val.style.borderRadius[1] +
        'px ' +
        val.style.borderRadius[2] +
        'px ' +
        val.style.borderRadius[3] +
        'px';
    }
  }
  //删除组件
  private delComp() {
    this.$emit('delCompPopUps', this.index[0], this.index[1]);
  }
}

regComp({pickUpPoint});
