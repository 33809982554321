
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {regComp} from '../../../comp';
import color from '../../operate/color/index.vue';
import serviceList from '../../operate/serviceList/index.vue';
import imgLoad from '../../operate/imgLoad/index.vue';

@Component({
  name: 'fab',
  components: {color, serviceList, imgLoad},
})
export default class fab extends Vue {
  static metadata = {
    name: '悬浮按钮',
    value: 'fab',
    type: '基础',
    icon: 'bill',
    schema: {
      type: 'object',
      properties: {
        setting: {
          type: 'object',
          properties: {
            direction: {
              title: '方向',
              type: 'string',
              'ui:widget': 'RadioWidget',
              enumNames: ['左下', '右下'],
              enum: ['left', 'right'],
              default: 'right',
            },
            style: {
              type: 'object',
              properties: {
                background: {
                  title: '背景色',
                  type: 'string',
                  'ui:widget': color,
                  default: 'rgba(255,255,255,1)',
                },
              },
            },
            btnList: {
              title: '按钮',
              type: 'array',
              minItems: 1,
              maxItems: 4,
              items: {
                type: 'object',
                'ui:field': serviceList,
              },
            },
          },
        },
      },
    },
    uiSchema: {},
    paramData: [],
  };
  @Prop()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setting: any;
  @Prop()
  private index: Array<number>;
  @Prop()
  private borderIndex: number;
  private styles: {
    background: string;
    left?: string;
    right?: string;
  } = {background: ''};

  @Watch('setting', {deep: true, immediate: true})
  changeSetting(val: {
    style: {
      background: string;
    };
    direction: string;
  }) {
    if (val.style) {
      if (val.style.background) {
        this.styles.background = val.style.background;
      }
    }
    if (val.direction) {
      if (val.direction === 'left') {
        delete this.styles.right;
        this.$set(this.styles, 'left', '20px');
      } else {
        delete this.styles.left;
        this.$set(this.styles, 'right', '20px');
      }
    }
  }
  //删除组件
  private delComp() {
    this.$emit('delCompPopUps', this.index[0], this.index[1]);
  }
}

regComp({fab});
