
import {Component, Vue, Model} from 'vue-property-decorator';
import {ObjectId} from 'bson';
import uploadImage from '@/components/uploadImage/index.vue';

@Component({
  name: 'uploadImg',
  components: {
    uploadImage,
  },
})
export default class extends Vue {
  @Model('input') value: string;

  private img = '';
  private action = '';
  private imgData = {};
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  async created() {
    this.img = this.value;
  }
  //选择图片
  private changeUploadImage(id: string) {
    this.img = id;
    this.$emit('input', this.img);
  }
}
