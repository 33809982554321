
import {ShopLabel} from '@/externals/MaxCI-ShopLabel-v1';
import {Component, Vue, Watch, Model} from 'vue-property-decorator';
import {shopLabels} from '../../../../../../../resources';
import {ObjectId} from 'bson';

interface LabelsShop extends ShopLabel {
  id: string;
}
@Component({
  name: 'multipleSelect',
})
export default class extends Vue {
  @Model('input') value: string;
  private label: Array<string> = [];
  private shopLabelsList: Array<LabelsShop> = [];
  async created() {
    const list = (await shopLabels.find(stage =>
      stage.$match(match => {
        match(
          f => f('spec')('applicationId'),
          e =>
            e.$eq(
              ObjectId.createFromHexString(this.$route.params.applicationId),
            ),
        );
        return match;
      }),
    )) as Array<LabelsShop>;
    list.forEach(item => {
      item.id = item._id.toHexString();
    });
    this.shopLabelsList = list;
    if (this.value) {
      this.shopLabelsList.forEach(item => {
        (JSON.parse(this.value) as Array<string>).forEach(valItem => {
          if (item.id === valItem) {
            this.label.push(valItem);
          }
        });
      });
    }
  }
  @Watch('label')
  changeLabel(val: string) {
    this.$emit('input', JSON.stringify(val));
  }
}
