
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {regComp} from '../../../comp';
import color from '../../operate/color/index.vue';

@Component({
  name: 'space',
  components: {color},
})
export default class space extends Vue {
  static metadata = {
    name: '空白占位',
    value: 'space',
    type: '基础',
    icon: 'bill',
    schema: {
      type: 'object',
      properties: {
        setting: {
          type: 'object',
          properties: {
            style: {
              type: 'object',
              properties: {
                background: {
                  title: '背景色',
                  type: 'string',
                  'ui:widget': color,
                  default: '#ffffff',
                },
                height: {
                  title: '高度',
                  type: 'number',
                  'ui:widget': 'ElSlider',
                  multipleOf: 5,
                  minimum: 5,
                  maximum: 1000,
                  default: 20,
                },
              },
            },
          },
        },
      },
    },
    uiSchema: {},
    paramData: [],
  };
  @Prop()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setting: any;
  @Prop()
  private index: Array<number>;
  @Prop()
  private borderIndex: number;
  private styles: {
    background: string;
    height: string;
  } = {background: '', height: ''};

  @Watch('setting', {deep: true, immediate: true})
  changeSetting(val: {
    style: {
      background: string;
      height: string;
    };
  }) {
    if (val.style) {
      if (val.style.background) {
        this.styles.background = val.style.background;
      }
      if (val.style.height) {
        this.styles.height = val.style.height + 'px';
      }
    }
  }
  //删除组件
  private delComp() {
    this.$emit('delCompPopUps', this.index[0], this.index[1]);
  }
}

regComp({space});
