
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import numberText from '../../operate/numberText/index.vue';
import agentList from '../../operate/agentList/index.vue';
import {regComp} from '../../../comp';
import color from '../../operate/color/index.vue';
import imgLoad from '../../operate/imgLoad/index.vue';

interface AgentData {
  activeImg: string;
  normalImg: string;
  text?: string;
  url?: string;
  labels: string;
  isShow: boolean;
  placeholder: string;
  flowChart: string;
}
@Component({
  name: 'agentOrder',
  components: {numberText, agentList, color, imgLoad},
})
export default class agentOrder extends Vue {
  static metadata = {
    name: '去下单',
    type: '跑腿',
    value: 'agentOrder',
    icon: 'canteen',
    schema: {
      type: 'object',
      properties: {
        setting: {
          type: 'object',
          properties: {
            agentData: {
              title: '代办种类',
              type: 'array',
              minItems: 4,
              maxItems: 4,
              default: [
                {
                  activeImg: '60879f3f40e790e3a88b18ad',
                  normalImg: '60879f5e40e790e3a88b18af',
                  text: '帮我取',
                  url: '',
                  labels: '',
                  isShow: true,
                  placeholder: '请输入内容',
                  flowChart: '6094ed28464caf3ad8f91989',
                },
                {
                  activeImg: '60879f9540e790e3a88b18b1',
                  normalImg: '60879fba40e790e3a88b18b3',
                  text: '帮我送',
                  url: '',
                  labels: '',
                  isShow: true,
                  placeholder: '请输入内容',
                  flowChart: '6094ed28464caf3ad8f91989',
                },
                {
                  activeImg: '60879ff240e790e3a88b18b5',
                  normalImg: '6087a00340e790e3a88b18b7',
                  text: '代购',
                  url: '',
                  labels: '',
                  isShow: true,
                  placeholder: '请输入内容',
                  flowChart: '6094ed51372a3c102e24b6c1',
                },
                {
                  activeImg: '6087a01540e790e3a88b18b9',
                  normalImg: '6087a02b40e790e3a88b18bb',
                  text: '万能帮',
                  url: '',
                  labels: '',
                  isShow: true,
                  placeholder: '请输入内容',
                  flowChart: '6094ed6127eaad88a1ff42c1',
                },
              ],
              items: {
                type: 'object',
                'ui:field': agentList,
              },
            },
            style: {
              type: 'object',
              properties: {
                background: {
                  title: '背景色',
                  type: 'string',
                  'ui:widget': color,
                  default: 'rgba(255,255,255,1)',
                },
                padding: {
                  title: '页面边距',
                  type: 'array',
                  minItems: 4,
                  maxItems: 4,
                  items: {
                    type: 'number',
                    'ui:field': numberText,
                  },
                  default: [0, 0, 0, 0],
                },
              },
            },
          },
        },
      },
    },
    uiSchema: {
      setting: {
        style: {
          padding: {
            'ui:options': {
              addable: false,
              sortable: false,
              removable: false,
              showIndexNumber: false,
            },
          },
        },
      },
    },
    paramData: [],
  };
  @Prop()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setting: any;
  @Prop()
  private index: Array<number>;
  @Prop()
  private borderIndex: number;
  private styles: {
    background: string;
    padding: string;
  } = {background: '', padding: ''};
  private agentData: Array<AgentData> = [];
  private agentName = '';
  private agentLabels: Array<string> = [];
  private placeholder = '';
  private flowChart = ''; //流程图

  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  @Watch('setting', {deep: true, immediate: true})
  changeSetting(val: {
    style: {
      background: string;
      padding: Array<number>;
      borderRadius: Array<number>;
    };
    agentData?: Array<AgentData>;
  }) {
    if (val.style) {
      if (val.style.background) {
        this.styles.background = val.style.background;
      }
      this.styles.padding =
        val.style.padding[0] +
        'px ' +
        val.style.padding[1] +
        'px ' +
        val.style.padding[2] +
        'px ' +
        val.style.padding[3] +
        'px';
    }
    if (val.agentData) {
      this.agentData = val.agentData;
      if (!this.agentName) {
        this.agentName = this.agentData[0].text ?? '';
      } else {
        val.agentData.forEach(item => {
          if (!item.isShow && item.text === this.agentName) {
            this.agentName = this.agentData[0].text ?? '';
          }
        });
      }
      val.agentData.forEach(item => {
        if (item.text === this.agentName) {
          this.placeholder = item.placeholder;
          this.agentLabels = item.labels.split(',');
          this.flowChart = item.flowChart;
        }
      });
    }
  }
  private changeTab(text: string, index: number) {
    this.agentName = text;
    this.agentLabels = this.agentData[index].labels.split(',');
    this.placeholder = this.agentData[index].placeholder;
    this.flowChart = this.agentData[index].flowChart;
  }
  //删除组件
  private delComp() {
    this.$emit('delCompPopUps', this.index[0], this.index[1]);
  }
}

regComp({agentOrder});
