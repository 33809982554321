
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {regComp} from '../../../comp';
import color from '../../operate/color/index.vue';
import navigateTo from '../../operate/navigateTo/index.vue';
import orderTab from '../../operate/orderTab/index.vue';

interface MenuData {
  name?: string;
  status?: string;
  type?: string;
}
@Component({
  name: 'agentOrderList',
  components: {color, navigateTo, orderTab},
})
export default class agentOrderList extends Vue {
  static metadata = {
    name: '任务大厅',
    value: 'agentOrderList',
    type: '跑腿',
    icon: 'bill',
    schema: {
      type: 'object',
      properties: {
        setting: {
          type: 'object',
          properties: {
            navigateTo: {
              title: '跳转详情',
              type: 'string',
              'ui:widget': navigateTo,
            },
            tab: {
              title: '订单导航',
              type: 'array',
              minItems: 1,
              maxItems: 8,
              items: {
                type: 'object',
                'ui:field': orderTab,
              },
            },
            style: {
              type: 'object',
              properties: {
                background: {
                  title: '背景色',
                  type: 'string',
                  'ui:widget': color,
                  default: '#ffffff',
                },
              },
            },
          },
        },
      },
    },
    uiSchema: {
      setting: {
        style: {
          padding: {
            'ui:options': {
              addable: false,
              sortable: false,
              removable: false,
              showIndexNumber: false,
            },
          },
          borderRadius: {
            'ui:options': {
              addable: false,
              sortable: false,
              removable: false,
              showIndexNumber: false,
            },
          },
        },
      },
    },
    paramData: [],
  };
  @Prop()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setting: any;
  @Prop()
  private index: Array<number>;
  @Prop()
  private borderIndex: number;
  private styles: {
    background: string;
  } = {background: ''};
  private menuData: Array<MenuData> = [];
  private menuIndex = 0;

  @Watch('setting', {deep: true, immediate: true})
  changeSetting(val: {
    style: {
      background: string;
    };
    tab?: Array<MenuData>;
  }) {
    if (val.style) {
      if (val.style.background) {
        this.styles.background = val.style.background;
      }
    }
    const menuData = [] as Array<MenuData>;
    if (val.tab) {
      val.tab.forEach(item => {
        if (item.name) {
          menuData.push(item);
        }
      });
      this.menuData = menuData;
    }
  }
  //删除组件
  private delComp() {
    this.$emit('delCompPopUps', this.index[0], this.index[1]);
  }
}

regComp({agentOrderList});
