
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import numberText from '../../operate/numberText/index.vue';
import {regComp} from '../../../comp';
import color from '../../operate/color/index.vue';
import serviceList from '../../operate/serviceList/index.vue';
import imgLoad from '../../operate/imgLoad/index.vue';
import navigateTo from '../../operate/navigateTo/index.vue';

interface ServiceData {
  img?: string;
  text?: string;
  url?: string;
}
@Component({
  name: 'goodsManege',
  components: {numberText, color, serviceList, imgLoad, navigateTo},
})
export default class goodsManege extends Vue {
  static metadata = {
    name: '商品管理',
    value: 'goodsManege',
    type: '商户',
    icon: 'bill',
    schema: {
      type: 'object',
      properties: {
        setting: {
          type: 'object',
          properties: {
            navigateTo: {
              title: '跳转地址',
              type: 'string',
              'ui:widget': navigateTo,
            },
            tipBanner: {
              type: 'string',
              title: '提示文字',
              default: '分类可以长按右边手柄上下拖动调换位置',
            },
            serviceArr: {
              title: '操作按钮',
              type: 'array',
              minItems: 1,
              maxItems: 4,
              items: {
                type: 'object',
                'ui:field': serviceList,
              },
            },
            style: {
              type: 'object',
              properties: {
                background: {
                  title: '背景色',
                  type: 'string',
                  'ui:widget': color,
                  default: '#ffffff',
                },
                padding: {
                  title: '页面边距',
                  type: 'array',
                  minItems: 4,
                  maxItems: 4,
                  items: {
                    type: 'number',
                    'ui:field': numberText,
                  },
                  default: [0, 0, 0, 0],
                },
                borderRadius: {
                  title: '圆角',
                  type: 'array',
                  minItems: 4,
                  maxItems: 4,
                  items: {
                    type: 'number',
                    'ui:field': numberText,
                  },
                  default: [0, 0, 0, 0],
                },
              },
            },
          },
        },
      },
    },
    uiSchema: {},
    paramData: [],
  };
  @Prop()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setting: any;
  @Prop()
  private index: Array<number>;
  @Prop()
  private borderIndex: number;
  private sortData = ['黄焖鸡米饭', '黄焖鸡土豆饭', '黄焖鸡配菜'];
  private serviceData: Array<ServiceData> = [];
  private tipBanner = '分类可以长按右边手柄上下拖动调换位置';
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  @Watch('setting', {deep: true, immediate: true})
  changeSetting(val: {tipBanner: string; serviceArr?: Array<ServiceData>}) {
    if (val.tipBanner) {
      this.tipBanner = val.tipBanner;
    }

    if (val.serviceArr) {
      const serviceData = [] as Array<ServiceData>;
      val.serviceArr.forEach(item => {
        serviceData.push({
          img: (this.downloadUrl ?? '') + item.img,
          text: item.text,
        });
      });
      this.serviceData = serviceData;
    }
  }
  //删除组件
  private delComp() {
    this.$emit('delCompPopUps', this.index[0], this.index[1]);
  }
}

regComp({goodsManege});
