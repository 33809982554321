import {VueConstructor} from 'vue';

type compsType = Record<
  string,
  {
    metadata: {
      name: string;
      type: string;
      value: string;
      icon: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      schema: any;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      uiSchema: any;
      paramData: Array<{name: string; type?: string; des?: string}>;
    };
  } & VueConstructor<Vue>
>;

export const comp: compsType = {};

export function regComp(_comp: compsType) {
  Object.assign(comp, _comp);
}
