
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {uploadImg} from '../../api/publicMethod';
import {ObjectId} from 'bson';
import {getUserId} from '../../api/publicMethod';
import {
  UploadImageBlur,
  UploadImageAutoOrient,
  UploadImageBright,
  UploadImageCircle,
  UploadImageContrast,
  UploadImageCrop,
  UploadImageFormat,
  UploadImageIndexCrop,
  UploadImageInterlace,
  UploadImageQuality,
  UploadImageResize,
  UploadImageRotate,
  UploadImageRoundedCorners,
  UploadImageSharpen,
} from '@/externals/MaxCI-UploadRequest-v1';

@Component({
  name: 'uploadImage',
})
export default class extends Vue {
  @Prop({type: String, default: 'image/*'})
  private accept: string;
  @Prop()
  private width: string;
  @Prop()
  private height: string;
  @Prop()
  private src: string;
  @Prop()
  private process: Array<
    | UploadImageAutoOrient
    | UploadImageBlur
    | UploadImageBright
    | UploadImageCircle
    | UploadImageContrast
    | UploadImageCrop
    | UploadImageFormat
    | UploadImageIndexCrop
    | UploadImageInterlace
    | UploadImageQuality
    | UploadImageResize
    | UploadImageRotate
    | UploadImageRoundedCorners
    | UploadImageSharpen
  >;

  private img = '';
  private action = '';
  private imgData = {};
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private userId: ObjectId = getUserId();
  private imageLoading = false;
  async created() {
    if (this.src) {
      this.img = this.src;
    }
  }
  @Watch('src')
  private changeSrc() {
    this.img = this.src;
  }
  //平台配置信息
  get platformConfig() {
    return this.$store.state.pConfig;
  }
  get image() {
    return this.img ? this.platformConfig.downloadUrl + this.img : '';
  }
  //图片上传成功
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private successAvatar(response: any) {
    this.img = response.file;
    this.$emit('changeImage', this.img);
  }
  //获取图片上传地址
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async beforeAvatarUpload(file: any) {
    const isJPEG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    const isPG = isJPEG || isPNG; //限制图片格式为jpg / png
    if (!isPG) {
      this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
      throw new Error();
    } else {
      this.imageLoading = true;
      const res = await uploadImg(
        this.platformConfig.channels[0].channelId,
        this.applicationId,
        false,
        this.process,
      );
      if (!res) {
        this.imageLoading = false;
        throw new Error();
      }
      this.imageLoading = false;
      this.action = res.status?.upload?.url ?? '';
      this.imgData = res.status?.upload?.data ?? {};
    }
  }
}
