
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {regComp} from '../../../comp';
import numberText from '../../operate/numberText/index.vue';
import color from '../../operate/color/index.vue';
import bannerUpload from '../../operate/bannerUpload/index.vue';
import imgLoad from '../../operate/imgLoad/index.vue';

@Component({
  name: 'positionButton',
  components: {numberText, color, bannerUpload, imgLoad},
})
export default class positionButton extends Vue {
  static metadata = {
    name: '固定按钮',
    value: 'positionButton',
    type: '基础',
    icon: 'bill',
    schema: {
      type: 'object',
      properties: {
        setting: {
          type: 'object',
          properties: {
            buttonArr: {
              title: '按钮',
              type: 'array',
              minItems: 1,
              maxItems: 4,
              items: {
                type: 'object',
                'ui:field': bannerUpload,
              },
            },
          },
        },
      },
    },
    uiSchema: {},
    paramData: [],
  };
  @Prop()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setting: any;
  @Prop()
  private index: Array<number>;
  @Prop()
  private borderIndex: number;
  private buttonData: Array<{img: string; url: string}> = [];

  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  @Watch('setting', {deep: true, immediate: true})
  changeSetting(val: {buttonArr?: Array<{img: string; url: string}>}) {
    const buttonData = [] as Array<{img: string; url: string}>;
    if (val.buttonArr) {
      val.buttonArr.forEach(item => {
        if (item.img) {
          buttonData.push(item);
        }
      });
    }
    this.buttonData = buttonData;
  }
  //删除组件
  private delComp() {
    this.$emit('delCompPopUps', this.index[0], this.index[1]);
  }
}

regComp({positionButton});
