
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {uITemplates} from '../../../../../resources';
import {getUserId} from '../../../../../api/publicMethod';
import {comp} from '../../decConfig/comp';
import Url from 'url';
import {ObjectId} from 'bson';
import {UIPage, UISubPage} from '@/externals/MaxCI-UITemplate-v1';

interface LinkData {
  sortName?: string;
  page?: Array<NameUIPage>;
}
interface ParamData {
  name?: string;
  value?: string;
  des?: string;
}
interface NameUIPage extends UIPage {
  name: string;
}
@Component({
  name: 'selectUrl',
  components: {
    ...comp,
  },
})
export default class extends Vue {
  @Prop()
  private linkUrl: string | null;
  @Prop()
  private title: string;
  private url: string | null = '';
  private currentPageName = ''; //选择的页面名称
  private linkName = '选择链接'; //选择内容/子页面名称
  private thumbnail = ''; //缩略图
  private paramData: Array<ParamData> = []; //填写参数
  private subPageData: Array<UISubPage> = []; //当前选中页面的子页面
  private subPageIndex = 10000; //选中子页面的index
  private pageIndex = 0; //选中的page的idnex
  private linkMenuIndex = 0; //选择链接左侧菜单index
  private linkData: Array<LinkData> = []; //链接数据
  private dialogLink = false;
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private detailId = ObjectId.createFromHexString(
    this.$route.params.templateId,
  );
  private userId: ObjectId = getUserId();
  async created() {
    // 数据回显
    this.url = this.linkUrl;
    if (this.url) {
      const url = Url.parse(this.url ?? '', true);
      this.linkName = (url.query.subpage as string) ?? '';
      this.currentPageName = (url.query.page as string) ?? '';
    }
  }
  //平台配置信息
  get platformConfig() {
    return this.$store.state.pConfig;
  }
  private async checkList() {
    try {
      //获取分类
      const uITemplate = (
        await uITemplates.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.detailId),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          ),
        )
      ).find(() => true);
      if (uITemplate) {
        const linkData = [] as Array<LinkData>;
        if (uITemplate?.spec.categories) {
          uITemplate?.spec.categories.forEach(item => {
            linkData.push({sortName: item, page: []});
          });
        }
        linkData.forEach(async item => {
          const page = [] as Array<NameUIPage>;
          for (const key in uITemplate.spec.pages) {
            if (uITemplate.spec.pages[key].category === item.sortName) {
              page.push({
                name: key,
                ...uITemplate.spec.pages[key],
              });
            }
          }
          item.page = page;
        });
        this.linkData = linkData;
        this.changeMenu(0);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    }
  }
  @Watch('linkUrl', {immediate: true, deep: true})
  private changeLinkUrl() {
    this.url = this.linkUrl;
    if (this.url) {
      const url = Url.parse(this.url ?? '', true);
      this.linkName = (url.query.subpage as string) ?? '';
      this.currentPageName = (url.query.page as string) ?? '';
    } else {
      this.linkName = '选择链接';
      this.currentPageName = '';
    }
  }
  //删除登录页、未授权页
  private delBtn() {
    this.$emit('clearPage', this.title);
  }
  //选择链接
  private selectLinkSumbit() {
    if (this.pageIndex === 10000) {
      this.$message.error('请选择页面');
    } else {
      if (this.subPageData.length > 0 && this.subPageIndex === 10000) {
        this.$message.error('请选择子页面');
      } else {
        const page = this.linkData[this.linkMenuIndex].page ?? [];
        let name = '';
        if (this.subPageIndex === 10000) {
          name = page[this.pageIndex].subPages[0].name;
        } else {
          name = page[this.pageIndex].subPages[this.subPageIndex].name;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let params = {} as any;
        this.paramData.forEach(item => {
          if (item.value && item.name) {
            params[item.name] = ObjectId.createFromHexString(item.value);
          }
        });
        const direction = this.title === '首页' ? 'back' : 'forward';
        this.url =
          'wx://' +
          direction +
          '.self/page?page=' +
          page[this.pageIndex].name +
          '&subpage=' +
          name +
          '&params=' +
          JSON.stringify(params);
        this.linkName = name;
        this.dialogLink = false;
        this.$emit('homeSumbit', this.url, this.title);
      }
    }
  }
  //选择页面
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private changePage(index: number, params: any) {
    this.pageIndex = index;
    this.subPageIndex = 10000;
    const page = this.linkData[this.linkMenuIndex].page ?? [];
    this.thumbnail = page[index].image ?? '';
    this.subPageData = page[index].subPages;
    //获取页面参数
    let paramString = [] as Array<string>;
    const paramData = [] as Array<ParamData>;
    page[index].subPages.forEach(item => {
      item.components.forEach(compItem => {
        if (comp[compItem.type]) {
          comp[compItem.type].metadata.paramData.forEach(paramItem => {
            paramString.push(JSON.stringify(paramItem));
          });
        }
      });
    });
    paramString = Array.from(new Set(paramString));
    for (let i = 0; i < paramString.length; i++) {
      paramData.push({
        name: JSON.parse(paramString[i]).name,
        des: JSON.parse(paramString[i]).des,
        value: params ? params[JSON.parse(paramString[i]).name] : '',
      });
    }
    this.paramData = paramData;
  }
  //切换子页面
  private changeSubPage(index: number) {
    this.subPageIndex = index;
  }
  //切换选择链接菜单
  private changeMenu(index: number) {
    this.linkMenuIndex = index;
    this.pageIndex = 0;
    this.subPageIndex = 10000;
    this.changePage(0, null);
  }
  //选择链接
  private async selectUrl() {
    await this.checkList();
    this.dialogLink = true;
    this.linkData.forEach((item, index) => {
      //判断是外部链接、其他小程序还是页面
      item.page?.forEach((pageItem, pageIndex) => {
        //页面名称是否相同
        if (this.currentPageName === pageItem.name) {
          pageItem.subPages.forEach((subPagesItem, subPagesIndex) => {
            //子页面名称是否相同
            if (subPagesItem.name === this.linkName) {
              this.linkMenuIndex = index;
              const url = Url.parse(this.url ?? '', true);
              if (typeof url.query.params === 'string') {
                this.changePage(pageIndex, JSON.parse(url.query.params));
              }
              this.subPageIndex = subPagesIndex;
            }
          });
        }
      });
    });
  }
}
