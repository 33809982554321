
import {Component, Vue} from 'vue-property-decorator';

@Component({
  name: 'menuList',
})
export default class extends Vue {
  private menuData = [
    {name: '微页面', icon: 'setting', route: 'microPage'},
    {name: '模板', icon: 'setting', route: 'decTemplate'},
  ];
  private menuIndex = this.$route.name;
  private changeMenu(route: string) {
    this.$router.push({
      path:
        '/application/' +
        this.$route.params.applicationId +
        '/channel/' +
        this.$route.params.channelId +
        '/decoration/' +
        route,
    });
  }
}
