
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {regComp} from '../../../comp';
import navigateTo from '../../operate/navigateTo/index.vue';
import radioSelect from '../../operate/radioSelect/index.vue';
import mallServices from '../../operate/mallServices/index.vue';
import usedServices from '../../operate/usedServices/index.vue';

@Component({
  name: 'bottomSubmit',
  components: {navigateTo, radioSelect, mallServices, usedServices},
})
export default class bottomSubmit extends Vue {
  static metadata = {
    name: '支付',
    value: 'bottomSubmit',
    type: '订单',
    icon: 'bill',
    schema: {
      type: 'object',
      properties: {
        setting: {
          type: 'object',
          'ui:options': {
            onlyShowIfDependent: true,
          },
          properties: {
            orderTypeDifferentiation: {
              title: '订单类型',
              type: 'string',
              'ui:widget': radioSelect,
            },
            type: {
              title: '类型',
              type: 'string',
              'ui:widget': 'RadioWidget',
              enumNames: ['支付', '提交'],
              enum: ['toPay', 'refund'],
              default: 'toPay',
            },
            navigateTo: {
              title: '跳转地址',
              type: 'string',
              'ui:widget': navigateTo,
            },
            mallServices: {
              title: '',
              type: 'string',
              'ui:widget': mallServices,
            },
            usedServices: {
              title: '',
              type: 'string',
              'ui:widget': usedServices,
            },
          },
          dependencies: {
            mallServices: ['orderTypeDifferentiation'],
            usedServices: ['orderTypeDifferentiation'],
          },
        },
      },
    },
    uiSchema: {},
    paramData: [],
  };
  @Prop()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setting: any;
  @Prop()
  private index: Array<number>;
  @Prop()
  private borderIndex: number;
  private type = '';
  @Watch('setting', {deep: true, immediate: true})
  changeSetting(val: {type?: string}) {
    if (val.type) {
      this.type = val.type;
    }
  }
  //删除组件
  private delComp() {
    this.$emit('delCompPopUps', this.index[0], this.index[1]);
  }
}

regComp({bottomSubmit});
