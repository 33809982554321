
import {Component, Vue, Prop} from 'vue-property-decorator';
import {regComp} from '../../../comp';
import navigateTo from '../../operate/navigateTo/index.vue';

@Component({
  name: 'mallGoodsPay',
  components: {navigateTo},
})
export default class mallGoodsPay extends Vue {
  static metadata = {
    name: '商品结算',
    value: 'mallGoodsPay',
    type: '商城',
    icon: 'bill',
    schema: {
      type: 'object',
      properties: {
        setting: {
          type: 'object',
          properties: {
            navigateTo: {
              title: '跳转地址',
              type: 'string',
              'ui:widget': navigateTo,
            },
            memberUrl: {
              title: '会员跳转地址',
              type: 'string',
              'ui:widget': navigateTo,
            },
            shopUrl: {
              title: '店铺跳转地址',
              type: 'string',
              'ui:widget': navigateTo,
            },
          },
        },
      },
    },
    uiSchema: {},
    paramData: [
      {name: 'shopId', des: '店铺名称', type: 'ObjectId', dataType: '店铺'},
    ],
  };
  @Prop()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setting: any;
  @Prop()
  private index: Array<number>;
  @Prop()
  private borderIndex: number;
  //删除组件
  private delComp() {
    this.$emit('delCompPopUps', this.index[0], this.index[1]);
  }
}

regComp({mallGoodsPay});
