
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {vueUtils} from '@lljj/vue-json-schema-form';
import {ObjectId} from 'bson';
import {comp} from '../../../comp';
import selectLink from '../selectLink/index.vue';
import uploadImage from '@/components/uploadImage/index.vue';

@Component({
  name: 'bannerUpload',
  components: {
    ...comp,
    selectLink,
    uploadImage,
  },
})
export default class extends Vue {
  @Prop()
  private rootFormData: Record<string, unknown>;
  @Prop()
  private curNodePath: string;

  private urlLink: {img?: string; url?: string} = {
    img: '',
    url: '',
  };
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  async created() {
    // 数据回显
    const urlLink = vueUtils.getPathVal(
      this.rootFormData,
      this.curNodePath,
    ) as {img?: string; text?: string; url?: string};
    if (urlLink.img) {
      this.urlLink = urlLink;
    }
  }
  private changeUrl(val: string) {
    this.urlLink.url = val;
  }
  @Watch('urlLink', {deep: true})
  changeUrlLink() {
    vueUtils.setPathVal(this.rootFormData, this.curNodePath, this.urlLink);
  }
  //选择图片
  private changeUploadImage(id: string) {
    this.urlLink.img = id;
  }
}
