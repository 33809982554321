
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {regComp} from '../../../comp';
import color from '../../operate/color/index.vue';
import numberText from '../../operate/numberText/index.vue';
import mallTab from '../../operate/mallTab/index.vue';
import navigateTo from '../../operate/navigateTo/index.vue';

interface MenuData {
  name: string;
}
@Component({
  name: 'mallRecommend',
  components: {numberText, color, mallTab, navigateTo},
})
export default class mallRecommend extends Vue {
  static metadata = {
    name: '商品推荐',
    value: 'mallRecommend',
    type: '商城',
    icon: 'bill',
    schema: {
      type: 'object',
      properties: {
        setting: {
          type: 'object',
          properties: {
            isShopShow: {
              type: 'boolean',
              title: '是否展示店铺',
              default: false,
            },
            navigateTo: {
              title: '跳转地址',
              type: 'string',
              'ui:widget': navigateTo,
            },
            menuData: {
              title: '菜单',
              type: 'array',
              default: [
                {
                  name: '默认',
                  description: '默认',
                  labels: [],
                },
              ],
              items: {
                type: 'object',
                'ui:field': mallTab,
              },
            },
            style: {
              type: 'object',
              properties: {
                background: {
                  title: '背景色',
                  type: 'string',
                  'ui:widget': color,
                  default: '#ffffff',
                },
                padding: {
                  title: '页面边距',
                  type: 'array',
                  minItems: 4,
                  maxItems: 4,
                  items: {
                    type: 'number',
                    'ui:field': numberText,
                  },
                  default: [0, 0, 0, 0],
                },
                borderRadius: {
                  title: '圆角',
                  type: 'array',
                  minItems: 4,
                  maxItems: 4,
                  items: {
                    type: 'number',
                    'ui:field': numberText,
                  },
                  default: [0, 0, 0, 0],
                },
              },
            },
          },
        },
      },
    },
    uiSchema: {
      setting: {
        style: {
          padding: {
            'ui:options': {
              addable: false,
              sortable: false,
              removable: false,
              showIndexNumber: false,
            },
          },
          borderRadius: {
            'ui:options': {
              addable: false,
              sortable: false,
              removable: false,
              showIndexNumber: false,
            },
          },
        },
      },
    },
    paramData: [
      {name: 'shopId', des: '店铺名称', type: 'ObjectId', dataType: '店铺'},
    ],
  };
  @Prop()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setting: any;
  @Prop()
  private index: Array<number>;
  @Prop()
  private borderIndex: number;
  private styles: {
    background: string;
    padding: string;
    borderRadius: string;
  } = {background: '', padding: '', borderRadius: ''};
  private menuData: Array<MenuData> = [];
  private isShopShow = false;

  @Watch('setting', {deep: true, immediate: true})
  changeSetting(val: {
    style: {
      background: string;
      padding: Array<number>;
      borderRadius: Array<number>;
    };
    placeholder: string;
    menuData: Array<MenuData>;
    isShopShow: boolean;
  }) {
    this.isShopShow = val.isShopShow;
    if (val.style) {
      if (val.style.background) {
        this.styles.background = val.style.background;
      }
      this.styles.padding =
        val.style.padding[0] +
        'px ' +
        val.style.padding[1] +
        'px ' +
        val.style.padding[2] +
        'px ' +
        val.style.padding[3] +
        'px';
      this.styles.borderRadius =
        val.style.borderRadius[0] +
        'px ' +
        val.style.borderRadius[1] +
        'px ' +
        val.style.borderRadius[2] +
        'px ' +
        val.style.borderRadius[3] +
        'px';
    }
    if (val.menuData) {
      const menuData = [] as Array<MenuData>;
      val.menuData.forEach(item => {
        menuData.push({
          name: item.name,
        });
      });
      this.menuData = menuData;
    }
  }
  //删除组件
  private delComp() {
    this.$emit('delCompPopUps', this.index[0], this.index[1]);
  }
}

regComp({mallRecommend});
