
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {regComp} from '../../../comp';
import navigateTo from '../../operate/navigateTo/index.vue';
import uploadImg from '../../operate/uploadImg/index.vue';
import imgLoad from '../../operate/imgLoad/index.vue';

@Component({
  name: 'competitionEntry',
  components: {navigateTo, uploadImg, imgLoad},
})
export default class competitionEntry extends Vue {
  static metadata = {
    name: '参赛入口',
    value: 'competitionEntry',
    type: '问卷',
    icon: 'bill',
    schema: {
      type: 'object',
      properties: {
        setting: {
          type: 'object',
          properties: {
            joinUrl: {
              title: '立即参赛链接',
              type: 'string',
              'ui:widget': navigateTo,
            },
            leaderboardUrl: {
              title: '排行榜链接',
              type: 'string',
              'ui:widget': navigateTo,
            },
            authenticationUrl: {
              title: '未认证跳转链接',
              type: 'string',
              'ui:widget': navigateTo,
            },
            entrancePictureImg: {
              title: '背景图',
              type: 'string',
              'ui:widget': uploadImg,
              default: '612cc758585582b43e75d96f',
            },
            leftButtonImg: {
              title: '立即参赛图片',
              type: 'string',
              'ui:widget': uploadImg,
              default: '612cc778585582b43e75d971',
            },
            rightButtonImg: {
              title: '排行榜图片',
              type: 'string',
              'ui:widget': uploadImg,
              default: '612cc78c585582b43e75d973',
            },
          },
        },
      },
    },
    uiSchema: {},
    paramData: [],
  };
  @Prop()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setting: any;
  @Prop()
  private index: Array<number>;
  @Prop()
  private borderIndex: number;
  private entrancePictureImg = '';
  private leftButtonImg = '';
  private rightButtonImg = '';
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  @Watch('setting', {deep: true, immediate: true})
  changeSetting(val: {
    entrancePictureImg: string;
    leftButtonImg: string;
    rightButtonImg: string;
  }) {
    if (val.entrancePictureImg) {
      this.entrancePictureImg = val.entrancePictureImg;
    }
    if (val.leftButtonImg) {
      this.leftButtonImg = val.leftButtonImg;
    }
    if (val.rightButtonImg) {
      this.rightButtonImg = val.rightButtonImg;
    }
  }
  //删除组件
  private delComp() {
    this.$emit('delCompPopUps', this.index[0], this.index[1]);
  }
}

regComp({competitionEntry});
