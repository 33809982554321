
import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
import {vueUtils} from '@lljj/vue-json-schema-form';

@Component({
  name: 'orderTab',
})
export default class extends Vue {
  @Prop()
  private rootFormData: Record<string, unknown>;
  @Prop()
  private curNodePath: string;
  private orderTab: {name?: string; phase?: string; type?: string} = {
    name: '',
    phase: '',
    type: '',
  };
  private type: Array<string> = [];
  private phase: Array<string> = [];
  private mallStatus: Array<string> = [
    '下单',
    '确认',
    '支付',
    '取消',
    '接单',
    '打包',
    '配送',
    '完成',
    '退款',
    '结算',
  ];
  private agentStatus: Array<string> = [
    '下单',
    '支付',
    '取消',
    '接单',
    '配送',
    '完成',
    '退款',
  ];
  private usedStatus: Array<string> = [
    '下单',
    '支付',
    '取消',
    '配送',
    '完成',
    '退款',
    '无效',
  ];
  private deliveryStatus: Array<string> = [
    '下单',
    '接单',
    '分拣',
    '到店',
    '取货',
    '完成',
    '无效',
    '取消',
  ];
  private rechargeStatus: Array<string> = [
    '下单',
    '支付',
    '充值',
    '退款',
    '完成',
    '无效',
    '取消',
  ];
  private statusLabels: Array<{name: string; list: Array<string>}> = [];
  created() {
    const orderTab = vueUtils.getPathVal(
      this.rootFormData,
      this.curNodePath,
    ) as {name?: string; phase?: string; type?: string};
    if (orderTab.name) {
      this.orderTab = orderTab;
      this.type = this.orderTab.type ? this.orderTab.type.split(',') : [];
      this.phase = this.orderTab.phase ? this.orderTab.phase.split(',') : [];
    }
  }
  private closePhase(index: number) {
    this.phase.splice(index, 1);
  }
  //选择快捷标签
  private changeLabel(index: number, labelIndex: number) {
    const label = this.statusLabels[index].list[labelIndex];
    if (this.phase.indexOf(label) === -1) {
      this.phase.push(label);
    }
  }
  @Watch('phase')
  private changePhase() {
    this.orderTab.phase = this.phase.join(',');
  }
  @Watch('type')
  private changeType() {
    const statusLabels = [] as Array<{name: string; list: Array<string>}>;
    this.type.forEach(item => {
      if (item === '商城') {
        statusLabels.push({name: item, list: this.mallStatus});
      } else if (item === '配送') {
        statusLabels.push({name: item, list: this.deliveryStatus});
      } else if (item === '跑腿') {
        statusLabels.push({name: item, list: this.agentStatus});
      } else if (item === '二手') {
        statusLabels.push({name: item, list: this.usedStatus});
      } else if (item === '充值') {
        statusLabels.push({name: item, list: this.rechargeStatus});
      }
    });
    this.statusLabels = statusLabels;
    this.orderTab.type = this.type.join(',');
  }
  @Watch('orderTab', {deep: true})
  changeOrderTab(val: string) {
    if (val !== '') {
      vueUtils.setPathVal(this.rootFormData, this.curNodePath, val);
    }
  }
}
