
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {vueUtils} from '@lljj/vue-json-schema-form';
import {ObjectId} from 'bson';
import {comp} from '../../../comp';
import selectShopLabels from '../selectShopLabels/index.vue';

interface Menu {
  name: string;
  labels: Array<string>;
}
@Component({
  name: 'mallTab',
  components: {
    ...comp,
    selectShopLabels,
  },
})
export default class extends Vue {
  @Prop()
  private rootFormData: Record<string, unknown>;
  @Prop()
  private curNodePath: string;

  private menu: Menu = {
    name: '',
    labels: [],
  };
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private detailId = ObjectId.createFromHexString(
    this.$route.params.templateId,
  );
  async created() {
    // 数据回显
    const menu = vueUtils.getPathVal(
      this.rootFormData,
      this.curNodePath,
    ) as Menu;
    if (menu.name) {
      this.menu = menu;
    }
  }
  @Watch('menu', {deep: true})
  changeUrlLink() {
    vueUtils.setPathVal(this.rootFormData, this.curNodePath, this.menu);
  }
  private changeLabels(labels: Array<string>) {
    this.menu.labels = labels;
  }
}
