var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"settle_list review_warp",style:(_vm.styles)},[_c('div',{staticClass:"review_border",class:{active: _vm.borderIndex === this.index[1]}}),_c('i',{staticClass:"el-icon-error review_close",on:{"click":_vm.delComp}}),_c('div',{staticClass:"settle_con"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._l((2),function(item,index){return _c('div',{key:index,staticClass:"settle_cgroup"},[_c('div',{staticClass:"settle_cgimg"}),_vm._m(4,true),_vm._m(5,true)])}),_vm._m(6),_vm._m(7)],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"request_refund"},[_c('div',{staticClass:"request_top"},[_vm._v("申请退款")]),_c('div',{staticClass:"request_text"},[_vm._v("请耐心等待客服处理")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"refund_info"},[_c('div',{staticClass:"info_li"},[_c('div',{staticClass:"info_name"},[_vm._v("退款金额")]),_c('div',{staticClass:"info_price"},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("￥")]),_vm._v("50 ")])]),_c('div',{staticClass:"info_li"},[_c('div',{staticClass:"info_name"},[_vm._v("退款方式")]),_c('div',{staticClass:"info_text"},[_vm._v("原路退还")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"process_box"},[_c('div',{staticClass:"process_top"},[_vm._v("售后进程")]),_c('div',{staticClass:"process_con"},[_c('div',{staticClass:"process_circle"}),_c('div',{staticClass:"process_right"},[_c('div',{staticClass:"process_prompt"},[_vm._v(" 您的退款申请已成功提交，等待客服处理 ")]),_c('div',{staticClass:"process_time"},[_vm._v("2021-07-06 15:16:24")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"settle_cgroup"},[_c('div',{staticClass:"settle_cgname"},[_vm._v("退款信息")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"settle_cginfo"},[_c('div',{staticClass:"settle_cgoodsname"},[_vm._v("黄焖鸡+腐竹+荷包蛋+米饭")]),_c('div',{staticClass:"settle_cgnum"},[_vm._v("已选规格:大份 / 加玉米 / 微辣")]),_c('div',{staticClass:"settle_cgnum"},[_vm._v("x2")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"settle_cgprice"},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("￥")]),_vm._v("25 ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"refund_reason"},[_c('div',{staticClass:"reason_top"},[_c('div',{staticClass:"reason_tname"},[_vm._v("退款原因")]),_vm._v(" 商品错送漏送 ")]),_c('div',{staticClass:"reason_con"},[_vm._v(" 就是一些退款原因，其实也没啥好看的，关键是你竟然读完了，你真闲 ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"order_ili"},[_c('div',{staticClass:"order_ileft"},[_vm._v("退款单号")]),_c('div',{staticClass:"order_iright"},[_vm._v("2130746694929946715")]),_c('div',{staticClass:"order_icopy"},[_vm._v("复制")])])
}]

export { render, staticRenderFns }