
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {regComp} from '../../../comp';
import bannerUpload from '../../operate/bannerUpload/index.vue';
import color from '../../operate/color/index.vue';
import numberText from '../../operate/numberText/index.vue';
import imgLoad from '../../operate/imgLoad/index.vue';

@Component({
  name: 'imageAd',
  components: {bannerUpload, color, numberText, imgLoad},
})
export default class imageAd extends Vue {
  static metadata = {
    name: '图片广告',
    value: 'imageAd',
    type: '基础',
    icon: 'school',
    schema: {
      type: 'object',
      properties: {
        setting: {
          type: 'object',
          properties: {
            images: {
              title: '图片',
              type: 'array',
              minItems: 1,
              default: [
                {
                  img: '6066d181dd9f1b1f9af7a725',
                  url: '',
                },
              ],
              items: {
                type: 'object',
                'ui:field': bannerUpload,
              },
            },
            style: {
              type: 'object',
              properties: {
                background: {
                  title: '背景色',
                  type: 'string',
                  'ui:widget': color,
                  default: '#ffffff',
                },
                padding: {
                  title: '页面边距',
                  type: 'array',
                  minItems: 4,
                  maxItems: 4,
                  items: {
                    type: 'number',
                    'ui:field': numberText,
                  },
                  default: [0, 0, 0, 0],
                },
                borderRadius: {
                  title: '圆角',
                  type: 'array',
                  minItems: 4,
                  maxItems: 4,
                  items: {
                    type: 'number',
                    'ui:field': numberText,
                  },
                  default: [0, 0, 0, 0],
                },
              },
            },
          },
        },
      },
    },
    uiSchema: {
      setting: {
        style: {
          padding: {
            'ui:options': {
              addable: false,
              sortable: false,
              removable: false,
              showIndexNumber: false,
            },
          },
          borderRadius: {
            'ui:options': {
              addable: false,
              sortable: false,
              removable: false,
              showIndexNumber: false,
            },
          },
        },
      },
    },
    paramData: [],
  };
  @Prop()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setting: any;
  @Prop()
  private index: Array<number>;
  @Prop()
  private borderIndex: number;
  private styles: {
    background: string;
    padding: string;
    borderRadius: string;
  } = {background: '', padding: '', borderRadius: ''};
  private bannerData: Array<{img: string; url: string}> = [];

  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  @Watch('setting', {deep: true, immediate: true})
  changeSetting(val: {
    images?: Array<{img: string; url: string}>;
    style?: {
      height: string;
      background: string;
      padding: Array<number>;
      borderRadius: Array<number>;
    };
  }) {
    const bannerData = [] as Array<{img: string; url: string}>;
    if (val.images) {
      val.images.forEach(item => {
        if (item.img) {
          bannerData.push(item);
        }
      });
    }
    this.bannerData = bannerData;
    if (val.style) {
      if (val.style.background) {
        this.styles.background = val.style.background;
      }
      this.styles.padding =
        val.style.padding[0] +
        'px ' +
        val.style.padding[1] +
        'px ' +
        val.style.padding[2] +
        'px ' +
        val.style.padding[3] +
        'px';
      this.styles.borderRadius =
        val.style.borderRadius[0] +
        'px ' +
        val.style.borderRadius[1] +
        'px ' +
        val.style.borderRadius[2] +
        'px ' +
        val.style.borderRadius[3] +
        'px';
    }
  }
  //删除组件
  private delComp() {
    this.$emit('delCompPopUps', this.index[0], this.index[1]);
  }
}

regComp({imageAd});
