
import {Component, Vue, Watch} from 'vue-property-decorator';
import menuList from '../components/menuList/index.vue';
import Submenu from '@/components/Submenu/index.vue';
import {
  uITemplates,
  sharedTemplateRequests,
  sharedTemplates,
} from '../../../../resources';
import {ObjectId} from 'bson';
import selectUrl from '../components/selectUrl/index.vue';
import {
  UIPage,
  UITemplate,
  UITemplateSpec,
} from '@/externals/MaxCI-UITemplate-v1';
import {uploadImg} from '../../../../api/publicMethod';
import {Form} from 'element-ui';
import {SharedTemplate} from '@/externals/MaxCI-SharedTemplate-v1';
import Url from 'url';
import escapeStringRegexp from 'escape-string-regexp';

import '../decConfig/components/review/search/index';
import '../decConfig/components/review/service/index';
import '../decConfig/components/review/shopList/index';
import '../decConfig/components/review/banner/index';
import '../decConfig/components/review/orderList/index';
import '../decConfig/components/review/userMineHead/index';
import '../decConfig/components/review/mineList/index';
import '../decConfig/components/review/storeInfo/index';
import '../decConfig/components/review/businessInfo/index';
import '../decConfig/components/review/selectOrderType/index';
import '../decConfig/components/review/settlementList/index';
import '../decConfig/components/review/remarks/index';
import '../decConfig/components/review/bottomSubmit/index';
import '../decConfig/components/review/orderStatus/index';
import '../decConfig/components/review/deleverInfo/index';
import '../decConfig/components/review/userOrderInfo/index';
import '../decConfig/components/review/addRemarks/index';
import '../decConfig/components/review/receivingAddress/index';
import '../decConfig/components/review/positionButton/index';
import '../decConfig/components/review/editAddress/index';
import '../decConfig/components/review/editUserInfo/index';
import '../decConfig/components/review/coupon/index';
import '../decConfig/components/review/couponUse/index';
import '../decConfig/components/review/accountBalance/index';
import '../decConfig/components/review/accountList/index';
import '../decConfig/components/review/withdrawal/index';
import '../decConfig/components/review/accountDetail/index';
import '../decConfig/components/review/refundSelectGoods/index';
import '../decConfig/components/review/refundSelectReason/index';
import '../decConfig/components/review/selectMap/index';
import '../decConfig/components/review/login/index';
import '../decConfig/components/review/shopOrderList/index';
import '../decConfig/components/review/createShop/index';
import '../decConfig/components/review/operationDetail/index';
import '../decConfig/components/review/goodsManege/index';
import '../decConfig/components/review/addGoods/index';
import '../decConfig/components/review/categoryManage/index';
import '../decConfig/components/review/specAndPro/index';
import '../decConfig/components/review/specDetails/index';
import '../decConfig/components/review/setSpec/index';
import '../decConfig/components/review/setAttr/index';
import '../decConfig/components/review/shopOrderSummary/index';
import '../decConfig/components/review/shopDailyAccount/index';
import '../decConfig/components/review/shopOrderStatistics/index';
import '../decConfig/components/review/shopMineHead/index';
import '../decConfig/components/review/textTips/index';
import '../decConfig/components/review/shopInfo/index';
import '../decConfig/components/review/shopMineStatus/index';
import '../decConfig/components/review/orderSetting/index';
import '../decConfig/components/review/tipBanner/index';
import '../decConfig/components/review/printerInfo/index';
import '../decConfig/components/review/printerList/index';
import '../decConfig/components/review/businessHours/index';
import '../decConfig/components/review/distributionOrderList/index';
import '../decConfig/components/review/distributionAddress/index';
import '../decConfig/components/review/orderRemarks/index';
import '../decConfig/components/review/distributionOrderInfo/index';
import '../decConfig/components/review/distributionProfit/index';
import '../decConfig/components/review/distributionOrderProductInfo/index';
import '../decConfig/components/review/distributionOrderSteps/index';
import '../decConfig/components/review/distributionOrderDetailFooter/index';
import '../decConfig/components/review/distributionGoToWork/index';
import '../decConfig/components/review/editRiderInfo/index';
import '../decConfig/components/review/sorterOrderList/index';
import '../decConfig/components/review/sorterOrderDetail/index';
import '../decConfig/components/review/distributionApplyForRegistration/index';
import '../decConfig/components/review/sorterApplyForRegistration/index';
import '../decConfig/components/review/distributorExamine/index';
import '../decConfig/components/review/distributionMineHead/index';
import '../decConfig/components/review/shopOrderAccountDetails/index';
import '../decConfig/components/review/shopOrderProductInfo/index';
import '../decConfig/components/review/shopOrderInfo/index';
import '../decConfig/components/review/shopOrderAmount/index';
import '../decConfig/components/review/shopFlow/index';
import '../decConfig/components/review/distributionOrderSummary/index';
import '../decConfig/components/review/distributionOrderAccountDetails/index';
import '../decConfig/components/review/distributionOrderStatistics/index';
import '../decConfig/components/review/distributionOrderAmount/index';
import '../decConfig/components/review/space/index';
import '../decConfig/components/review/sorterOrderAccountDetails/index';
import '../decConfig/components/review/agentOrder/index';
import '../decConfig/components/review/agentTask/index';
import '../decConfig/components/review/agentExpectedTime/index';
import '../decConfig/components/review/agentErrandFee/index';
import '../decConfig/components/review/agentPay/index';
import '../decConfig/components/review/agentOrderList/index';
import '../decConfig/components/review/agentOrderDetailAddress/index';
import '../decConfig/components/review/agentOrderDetailRemark/index';
import '../decConfig/components/review/agentOrderDetailCert/index';
import '../decConfig/components/review/agentOrderDetailCode/index';
import '../decConfig/components/review/agentOrderInfo/index';
import '../decConfig/components/review/agentOrderDetailIncome/index';
import '../decConfig/components/review/agentOrderDetailFooter/index';
import '../decConfig/components/review/userAgentOrderInfo/index';
import '../decConfig/components/review/userAgentOrderCode/index';
import '../decConfig/components/review/withdrawalStatus/index';
import '../decConfig/components/review/mallSeckill/index';
import '../decConfig/components/review/mallPopular/index';
import '../decConfig/components/review/mallRecommend/index';
import '../decConfig/components/review/mallCart/index';
import '../decConfig/components/review/mallMineOrder/index';
import '../decConfig/components/review/mallCategory/index';
import '../decConfig/components/review/mallGoodsInfo/index';
import '../decConfig/components/review/mallSelectCoupon/index';
import '../decConfig/components/review/mallGoodsDetails/index';
import '../decConfig/components/review/mallGoodsPay/index';
import '../decConfig/components/review/marketList/index';
import '../decConfig/components/review/marketGoodsDetail/index';
import '../decConfig/components/review/marketGoodsPay/index';
import '../decConfig/components/review/marketTask/index';
import '../decConfig/components/review/marketProductFee/index';
import '../decConfig/components/review/marketReleaseBut/index';
import '../decConfig/components/review/deliverGoods/index';
import '../decConfig/components/review/marketReleaseList/index';
import '../decConfig/components/review/marketGoodsInfo/index';
import '../decConfig/components/review/marketOrderList/index';
import '../decConfig/components/review/fab/index';
import '../decConfig/components/review/marketOrderStatus/index';
import '../decConfig/components/review/marketOrderDeleverInfo/index';
import '../decConfig/components/review/marketOrderInfo/index';
import '../decConfig/components/review/marketOrderGoodsInfo/index';
import '../decConfig/components/review/partTimeFormSubmit/index';
import '../decConfig/components/review/imageAd/index';
import '../decConfig/components/review/marketCateory/index';
import '../decConfig/components/review/operationWeekly/index';
import '../decConfig/components/review/operatingData/index';
import '../decConfig/components/review/refundProgress/index';
import '../decConfig/components/review/refundOrderStatus/index';
import '../decConfig/components/review/searchPlaceholder/index';
import '../decConfig/components/review/todayDataSummary/index';
import '../decConfig/components/review/vipStatus/index';
import '../decConfig/components/review/vipCard/index';
import '../decConfig/components/review/vipPackage/index';
import '../decConfig/components/review/vipPay/index';
import '../decConfig/components/review/integralList/index';
import '../decConfig/components/review/telephoneCharges/index';
import '../decConfig/components/review/telephoneChargesDetail/index';
import '../decConfig/components/review/pickUpPoint/index';
import '../decConfig/components/review/competitionAuthentication/index';
import '../decConfig/components/review/competitionList/index';
import '../decConfig/components/review/competitionAnswer/index';
import '../decConfig/components/review/competitionResult/index';
import '../decConfig/components/review/userNamePhone/index';
import '../decConfig/components/review/competitionEntry/index';
import '../decConfig/components/review/competitionRankingList/index';
import '../decConfig/components/review/sorterOrderBinding/index';
import '../decConfig/components/review/collectCoupons/index';
import '../decConfig/components/review/cabinetInfo/index';
import '../decConfig/components/review/cabinetRequest/index';
import '../decConfig/components/review/cabinetTakeMeal/index';
import '../decConfig/components/review/shopChange/index';
import '../decConfig/components/review/shopSummaryMonth/index';
import '../decConfig/components/review/shopIcomeDetail/index';
import '../decConfig/components/review/shopRelatedInformation/index';
import '../decConfig/components/review/shopHistoricalFlow/index';
import '../decConfig/components/review/authorizedPosition/index';
import '../decConfig/components/review/selectPosition/index';
import '../decConfig/components/review/shopAccountList/index';
import '../decConfig/components/review/payInfo/index';

import selectLink from '../decConfig/components/operate/selectLink/index.vue';

interface NameUIPage extends UIPage {
  name: string;
  oldName?: string;
}
interface AvailableTemplate {
  templateName: string;
  show: boolean;
  categories: Array<{
    categoryName: string;
    pages: Array<NameUIPage>;
  }>;
}
@Component({
  name: 'microPage',
  components: {
    menuList,
    Submenu,
    selectUrl,
    selectLink,
  },
})
export default class extends Vue {
  private selectPageIndex = [0, 0, 10000]; //替换页面 选中的页面
  private availableIndex = [0, 0, 10000]; //替换页面 切换的模板 分类
  private availableTemplateData: Array<AvailableTemplate> = []; //查询可用模板名称
  private dialogReplace = false; //替换模板
  private importTemplate: Array<UITemplate> = []; //导入模板数据
  private dialogImport = false; //导入模板弹窗
  private mineTemplateData: Array<SharedTemplate> = []; //自己上传的模板
  private dialogSelect = false; //选择模板
  private submitFlag = false;
  private action = '';
  private imgData = {};
  private formData = {
    name: '',
    description: '',
    cover: '',
    images: [] as Array<string>,
  };
  private rules = {
    name: [{required: true, message: '请输入模板名称', trigger: 'blur'}],
    description: [{required: true, message: '请输入描述介绍', trigger: 'blur'}],
    cover: [{required: true, message: '请选择封面', trigger: 'blur'}],
  };
  private dialogTemplate = false; // 新建更新模板弹窗
  private titleTemplate = '';
  private dialogUpload = false; //上传模板弹窗
  private uiAuthorize = '' as string | null; //设置未授权页链接
  private uiIndex = ''; //设置首页链接
  private uiLogin = '' as string | null; //设置登录页链接
  private pageList: Array<NameUIPage> = [];
  private dialogDelPage = false; //删除页面弹窗
  private addPageText = ''; //新建页面名称
  private dialogPage = false; //新建页面弹窗
  private delSortName = ''; //删除分类名称
  private dialogDelSort = false; //删除分类弹窗
  private addSortText = ''; //新建分类名称
  private dialogSort = false; //新建分类弹窗
  private submenu = [] as Array<{
    top: string;
    list: Array<{name: string; active: boolean; close?: boolean}>;
  }>;
  private detailId = ObjectId.createFromHexString(
    this.$route.params.templateId,
  );
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private roleName = localStorage.getItem('roleName');
  private sortName = ''; //分类名称
  private oldSortName = ''; //旧分类名称
  private uITemplateMsg: null | UITemplate = null;
  private operateId: ObjectId | null = null; //操作的id
  private operateName = ''; //操作的名称
  private operateCategory = ''; //操作的分类
  async created() {
    //查询分类
    this.updateSort();
    //查询自己上传的模板
    this.updateMineTem();
    //查询可导入的模板
    this.updateImportData();
    //查询可用模板
  }
  @Watch('$route.query', {deep: true})
  private changeRouteQuery() {
    if (this.$route.query.menu) {
      this.sortName = (this.$route.query.menu ?? '') as string;
      this.oldSortName = (this.$route.query.menu ?? '') as string;
      this.updatePage();
    }
  }
  //平台配置信息
  get platformConfig() {
    return this.$store.state.pConfig;
  }
  //查询分类
  async updateSort() {
    try {
      let submenu = [] as Array<{
        top: string;
        list: Array<{name: string; active: boolean; close?: boolean}>;
      }>;
      //查询
      const uITemplate = (
        await uITemplates.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.detailId),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          ),
        )
      ).find(() => true);
      if (uITemplate) {
        submenu = [
          {
            top: '',
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            list: uITemplate?.spec.categories!.map(v => {
              let close = true;
              for (const key in uITemplate.spec.pages) {
                if (uITemplate.spec.pages[key].category === v) {
                  close = false;
                  break;
                }
              }
              return {
                name: v,
                active: false,
                close: close,
              };
            }),
          },
        ];
        if (this.$route.query.menu) {
          this.sortName = (this.$route.query.menu ?? '') as string;
          this.oldSortName = (this.$route.query.menu ?? '') as string;
        } else {
          this.sortName = submenu[0].list[0].name;
          this.oldSortName = submenu[0].list[0].name;
        }
        let unclassified = '';
        for (let item in uITemplate?.spec.pages) {
          if (!uITemplate?.spec.pages[item].category) {
            unclassified = '未分类';
          }
        }
        if (unclassified === '未分类') {
          submenu[0].list.push({
            name: '未分类',
            active: false,
            close: false,
          });
        }
        this.submenu = submenu;
        this.updatePage();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //查询模板
  private async checkUITemplate() {
    const uITemplate = (
      await uITemplates.find(stage =>
        stage.$match(match =>
          match(
            f => f('_id'),
            e => e.$eq(this.detailId),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        ),
      )
    ).find(() => true);
    this.uITemplateMsg = uITemplate ?? null;
    this.uiIndex = uITemplate?.spec.index ?? '';
    this.uiLogin = uITemplate?.spec.login ?? '';
    this.uiAuthorize = uITemplate?.spec.authorize ?? '';
  }
  //查询页面
  private async updatePage() {
    try {
      await this.checkUITemplate();
      if (this.uITemplateMsg) {
        const pageList = [] as Array<NameUIPage>;
        for (const key in this.uITemplateMsg.spec.pages) {
          if (this.sortName === '未分类') {
            if (!this.uITemplateMsg.spec.pages[key].category) {
              pageList.push({
                name: key,
                oldName: key,
                ...this.uITemplateMsg.spec.pages[key],
              });
            }
          } else {
            if (this.uITemplateMsg.spec.pages[key].category === this.sortName) {
              pageList.push({
                name: key,
                oldName: key,
                ...this.uITemplateMsg.spec.pages[key],
              });
            }
          }
        }
        this.pageList = pageList;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //查询可导入的模板
  private async updateImportData() {
    const uITemplate = await uITemplates.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      ),
    );
    this.importTemplate = uITemplate;
    const availableTemplateData = [] as Array<AvailableTemplate>;
    uITemplate.forEach((item, index) => {
      availableTemplateData.push({
        templateName: item.spec.name,
        show: index === 0 ? true : false,
        categories: item.spec.categories.map(v => {
          const pages = [];
          for (const name in item.spec.pages) {
            if (v === item.spec.pages[name].category) {
              pages.push({
                name: name,
                ...item.spec.pages[name],
              });
            }
          }
          return {
            categoryName: v,
            pages: pages,
          };
        }),
      });
    });
    this.availableTemplateData = availableTemplateData;
  }
  //获取页面缩略图
  private getImage(spec: UITemplateSpec) {
    if (typeof spec.index === 'string') {
      const url = Url.parse(spec.index ?? '', true);
      return spec.pages[url.query.page as string] &&
        spec.pages[url.query.page as string].image
        ? spec.pages[url.query.page as string].image
        : '';
    }
  }
  //替换页面选择分类
  private selectCategory(index: number, categoryIndex: number) {
    this.$set(this.availableIndex, 0, index);
    this.$set(this.availableIndex, 1, categoryIndex);
    if (
      index === this.selectPageIndex[0] &&
      categoryIndex === this.selectPageIndex[1]
    ) {
      this.$set(this.availableIndex, 2, this.selectPageIndex[2]);
    } else {
      this.$set(this.availableIndex, 2, 10000);
    }
  }
  //替换页面选择页面
  private selectPage() {
    this.selectPageIndex = this.availableIndex.map(v => v);
  }
  //替换页面保存
  private replaceSumbit() {
    if (this.selectPageIndex[2] === 10000) {
      this.$message.error('请选择页面');
    } else {
      const page = JSON.parse(
        JSON.stringify(
          this.availableTemplateData[this.selectPageIndex[0]].categories[
            this.selectPageIndex[1]
          ].pages[this.selectPageIndex[2]],
        ),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) as any;
      delete page.name;
      page.category = this.operateCategory;
      uITemplates
        .update(
          filter =>
            filter(
              f => f('_id'),
              e => e.$eq(this.detailId),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          update =>
            update.$set(s =>
              s(f => f('spec')('pages')(this.operateName), page as UIPage),
            ),
        )
        .then(res => {
          if (res.length > 0) {
            this.dialogReplace = false;
            this.selectPageIndex = [0, 0, 10000];
            this.availableIndex = [0, 0, 10000];
            this.updatePage();
          }
        });
    }
  }
  //替换页面弹窗
  private replacePagePopUps(name: string, category: string) {
    this.operateName = name;
    this.operateCategory = category;
    this.dialogReplace = true;
  }
  //修改分类名称
  private changeSortName() {
    if (this.sortName) {
      let index = 100000;
      this.uITemplateMsg?.spec.categories.forEach((item, i) => {
        if (item === this.oldSortName) {
          index = i;
        }
      });
      const pages = this.uITemplateMsg?.spec.pages;
      for (const key in pages) {
        if (pages[key].category === this.oldSortName) {
          pages[key].category = this.sortName;
        }
      }
      if (index !== 100000) {
        uITemplates
          .update(
            filter =>
              filter(
                f => f('_id'),
                e => e.$eq(this.detailId),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              ),
            update =>
              update
                .$set(s =>
                  s(f => f('spec')('categories')(index), this.sortName),
                )
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                .$set(s => s(f => f('spec')('pages'), pages!)),
          )
          .then(res => {
            if (res.length) {
              this.$router.push({
                query: {menu: this.sortName},
              });
              this.oldSortName = this.sortName;
              this.updateSort();
            }
          });
      }
    } else {
      this.sortName = this.oldSortName;
    }
  }
  //修改页面名称
  private async changeName(index: number) {
    if (this.pageList[index].name) {
      if (this.pageList[index].name !== this.pageList[index].oldName) {
        //判断名称是否重复
        let num = 0;
        this.pageList.forEach(item => {
          if (item.oldName === this.pageList[index].name) {
            num++;
          }
        });
        if (num > 0) {
          this.pageList[index].name = this.pageList[index].oldName ?? '';
          this.$message.error('页面名称不能重复');
          return;
        }
        // //修改名称
        // const uITemplate = await uITemplates.update(
        //   filter =>
        //     filter(
        //       f => f('_id'),
        //       e => e.$eq(this.detailId),
        //     )(
        //       f => f('spec')('applicationId'),
        //       e => e.$eq(this.applicationId),
        //     ),
        //   update =>
        //     update.$rename(s =>
        //       s(
        //         f => f('spec')('pages')(this.pageList[index].oldName as string),
        //         `spec.pages.${this.pageList[index].name}`,
        //       ),
        //     ),
        // );
        //更新页面
        await this.checkUITemplate();
        //修改所有组件设置中页面链接中页面的名称
        const pages = this.uITemplateMsg?.spec.pages;
        if (pages) {
          pages[this.pageList[index].name] =
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            pages[this.pageList[index].oldName!];
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          delete pages[this.pageList[index].oldName!];
          this.changeUrl(
            pages,
            this.pageList[index].oldName ?? '',
            this.pageList[index].name,
          );
          //修改首页 登录页 未授权页链接中的页面名称
          const uiIndex =
            this.uITemplateMsg?.spec.index.replace(
              `page=${this.pageList[index].oldName}`,
              `page=${this.pageList[index].name}`,
            ) ?? '';
          const uiLogin = this.uITemplateMsg?.spec.login
            ? this.uITemplateMsg?.spec.login.replace(
                `page=${this.pageList[index].oldName}`,
                `page=${this.pageList[index].name}`,
              ) ?? ''
            : '';
          const uiAuthorize = this.uITemplateMsg?.spec.authorize
            ? this.uITemplateMsg?.spec.authorize.replace(
                `page=${this.pageList[index].oldName}`,
                `page=${this.pageList[index].name}`,
              ) ?? ''
            : '';

          uITemplates
            .update(
              filter =>
                filter(
                  f => f('_id'),
                  e => e.$eq(this.detailId),
                )(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                ),
              update =>
                update
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  .$set(s => s(f => f('spec')('pages'), pages!))
                  .$set(s => s(f => f('spec')('index'), uiIndex))
                  .$set(s => {
                    if (uiLogin) {
                      s(f => f('spec')('login'), uiLogin);
                    }
                    return s;
                  })
                  .$set(s => {
                    if (uiAuthorize) {
                      s(f => f('spec')('authorize'), uiAuthorize);
                    }
                    return s;
                  }),
            )
            .then(res => {
              if (res.length > 0) {
                this.pageList[index].oldName = this.pageList[index].name;
                this.checkUITemplate();
              }
            });
        }
      }
    } else {
      this.pageList[index].name = this.pageList[index].oldName ?? '';
    }
  }
  //数组去重
  private delRepeat(arr: Array<string>) {
    let hash = [];
    for (var i = 0; i < arr.length; i++) {
      if (arr.indexOf(arr[i]) == i) {
        hash.push(arr[i]);
      }
    }
    return hash;
  }
  //修改导入页面的链接 wx://forward.self/page?page=店铺详情(1)&subpage=店铺详情
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private changeUrl(val: any, oldName: string, newName: string) {
    if (typeof val === 'string') {
      const reg = escapeStringRegexp(oldName);
      if (val.match(new RegExp(`^wx:\\/\\/.+page=${reg}.+$`))) {
        return val.replace(`page=${oldName}`, `page=${newName}`);
      }
    } else if (val !== null && typeof val === 'object') {
      for (const key in val) {
        const v = this.changeUrl(val[key], oldName, newName);
        if (v !== val[key]) {
          val[key] = v;
        }
      }
    }
    return val;
  }
  //导入模板
  private importSubmit(index: number) {
    const pages = this.importTemplate[index].spec.pages;
    const selfPages = this.uITemplateMsg?.spec.pages;
    const categories = this.uITemplateMsg?.spec.categories.concat(
      this.importTemplate[index].spec.categories,
    ) as Array<string>;
    let uiIndex = this.importTemplate[index].spec.index,
      uiLogin = this.importTemplate[index].spec.login ?? '',
      uiAuthorize = this.importTemplate[index].spec.authorize ?? '';
    //判断是否有重复的名称 有就修改名称和页面链接
    let newPages: typeof pages = {};
    if (selfPages) {
      for (const name in pages) {
        const cpage = pages[name];
        for (let i = 0; ; i++) {
          const newName = i === 0 ? name : name + '(' + i + ')';
          if (!Object.keys(selfPages).find(pageName => pageName === newName)) {
            if (name !== newName) {
              this.changeUrl(pages, name, newName);
              //修改首页，登录页，未授权页
              const urlIndex = Url.parse(
                this.importTemplate[index].spec.index ?? '',
                true,
              );
              const urlLogin = Url.parse(
                this.importTemplate[index].spec.login ?? '',
                true,
              );
              const urlAuthorize = Url.parse(
                this.importTemplate[index].spec.authorize ?? '',
                true,
              );
              if (urlIndex.query.page === name) {
                uiIndex = this.changeUrl(
                  this.importTemplate[index].spec.index,
                  name,
                  newName,
                );
              }
              if (urlLogin.query.page === name) {
                uiLogin = this.changeUrl(
                  this.importTemplate[index].spec.login,
                  name,
                  newName,
                );
              }
              if (urlAuthorize.query.page === name) {
                uiAuthorize = this.changeUrl(
                  this.importTemplate[index].spec.authorize,
                  name,
                  newName,
                );
              }
            }
            newPages[newName] = cpage;
            break;
          }
        }
      }
    } else {
      newPages = pages;
    }
    uITemplates
      .update(
        filter =>
          filter(
            f => f('_id'),
            e => e.$eq(this.detailId),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        update =>
          update
            .$set(s => {
              for (const name in newPages) {
                s(f => f('spec')('pages')(name), newPages[name]);
              }
              return s;
            })
            .$set(s =>
              s(
                f => f('spec')('categories'),
                this.delRepeat(categories) as [string, ...string[]],
              ),
            )
            .$set(s => s(f => f('spec')('index'), uiIndex))
            .$set(s => {
              if (this.importTemplate[index].spec.login) {
                s(f => f('spec')('login'), uiLogin);
              }
              return s;
            })
            .$set(s => {
              if (this.importTemplate[index].spec.authorize) {
                s(f => f('spec')('authorize'), uiAuthorize);
              }
              return s;
            }),
      )
      .then(res => {
        if (res.length > 0) {
          this.$message.success('导入成功');
          this.dialogImport = false;
          this.updateSort();
        }
      });
  }
  //更新模板弹窗
  private updatePopUps(index: number) {
    this.operateId = this.mineTemplateData[index]._id;
    this.dialogTemplate = true;
    this.dialogUpload = false;
    //回显数据
    this.formData.name = this.mineTemplateData[index].spec.name;
    this.formData.description =
      this.mineTemplateData[index].spec.description ?? '';
    this.formData.cover = this.mineTemplateData[index].spec.images[0];
    this.formData.images = this.mineTemplateData[index].spec.images.filter(
      (item, index) => {
        if (index > 0) {
          return item;
        }
      },
    );
  }
  //查询自己上传的模板
  private async updateMineTem() {
    this.mineTemplateData = await sharedTemplates.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      ),
    );
  }
  //新建更新模板返回
  private addBack() {
    this.dialogUpload = true;
    this.dialogSelect = false;
    this.dialogTemplate = false;
  }
  //新建更新模板弹窗
  private addPopUps(title: string) {
    this.dialogUpload = false;
    this.titleTemplate = title + '模板';
    this.formData.name = '';
    this.formData.description = '';
    this.formData.cover = '';
    this.formData.images = [];
    if (this.titleTemplate === '新建模板') {
      this.dialogTemplate = true;
    } else {
      this.dialogSelect = true;
    }
  }
  //新建更新模板保存
  private uploadSumbit() {
    (this.$refs.formData as Form).validate(valid => {
      if (valid) {
        try {
          this.submitFlag = true;
          if (this.uITemplateMsg) {
            const images = [this.formData.cover] as Array<string>;
            this.formData.images.forEach(item => {
              images.push(item);
            });
            if (this.titleTemplate === '新建模板') {
              sharedTemplateRequests
                .create(
                  [
                    {
                      spec: {
                        type: '创建',
                        name: this.formData.name,
                        description: this.formData.description,
                        applicationId: this.applicationId,
                        dependencies: this.uITemplateMsg?.spec.dependencies,
                        data: {
                          name: this.uITemplateMsg?.spec.name,
                          index: this.uITemplateMsg?.spec.index,
                          ...(this.uITemplateMsg?.spec.login
                            ? {login: this.uITemplateMsg?.spec.login}
                            : {}),
                          ...(this.uITemplateMsg?.spec.authorize
                            ? {authorize: this.uITemplateMsg?.spec.authorize}
                            : {}),
                          pages: this.uITemplateMsg?.spec.pages,
                          categories: this.uITemplateMsg?.spec.categories,
                        },
                        images: images as [string, ...string[]],
                      },
                    },
                  ],
                  {
                    watch: {
                      filter: filter =>
                        filter(
                          f => f('operationType'),
                          e => e.$eq('update'),
                        )(
                          f => f('fullDocument')('status')('phase'),
                          e => e.$exists(true),
                        ),
                    },
                    fullResource: true,
                  },
                )
                .then(res => {
                  if (res[0].status?.phase === '成功') {
                    this.dialogTemplate = false;
                    this.$message.success('上传成功');
                  } else {
                    this.$message.error(
                      res[0].status?.conditions[0].message ?? '',
                    );
                  }
                });
            } else {
              sharedTemplateRequests
                .create(
                  [
                    {
                      spec: {
                        type: '更新',
                        dependencies: this.uITemplateMsg?.spec.dependencies,
                        data: {
                          name: this.uITemplateMsg?.spec.name,
                          index: this.uITemplateMsg?.spec.index,
                          ...(this.uITemplateMsg?.spec.login
                            ? {login: this.uITemplateMsg?.spec.login}
                            : {}),
                          ...(this.uITemplateMsg?.spec.authorize
                            ? {authorize: this.uITemplateMsg?.spec.authorize}
                            : {}),
                          pages: this.uITemplateMsg?.spec.pages,
                          categories: this.uITemplateMsg?.spec.categories,
                        },
                        images: images as [string, ...string[]],
                        sharedTemplateId: this.operateId as ObjectId,
                        description: this.formData.description,
                        applicationId: this.applicationId,
                      },
                    },
                  ],
                  {
                    watch: {
                      filter: filter =>
                        filter(
                          f => f('operationType'),
                          e => e.$eq('update'),
                        )(
                          f => f('fullDocument')('status')('phase'),
                          e => e.$exists(true),
                        ),
                    },
                    fullResource: true,
                  },
                )
                .then(res => {
                  if (res[0].status?.phase === '成功') {
                    this.dialogTemplate = false;
                    this.dialogUpload = false;
                    this.dialogSelect = false;
                    this.$message.success('更新成功');
                  } else {
                    this.$message.error(
                      res[0].status?.conditions[0].message ?? '',
                    );
                  }
                });
            }
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          console.log(e);
          this.$message.error('网络异常，请稍后重试');
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  //清除登录页  未授权页
  private async clearPage(type: string) {
    if (type === '登录页') {
      await uITemplates.update(
        filter =>
          filter(
            f => f('_id'),
            e => e.$eq(this.detailId),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        update => update.$set(s => s(f => f('spec')('login'), '')),
      );
      this.uiLogin = null;
    } else if (type === '未授权页') {
      await uITemplates.update(
        filter =>
          filter(
            f => f('_id'),
            e => e.$eq(this.detailId),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        update => update.$set(s => s(f => f('spec')('authorize'), '')),
      );
      this.uiAuthorize = null;
    }
    this.checkUITemplate();
  }
  //删除页面提示
  private delPagePopUps(name: string) {
    this.operateName = name;
    this.dialogDelPage = true;
  }
  //删除页面保存
  private async delPageSumbit() {
    try {
      // uITemplates
      //   .update(
      //     filter =>
      //       filter(
      //         f => f('_id'),
      //         e => e.$eq(this.detailId),
      //       )(
      //         f => f('spec')('applicationId'),
      //         e => e.$eq(this.applicationId),
      //       )(
      //         f => f('spec')('pages')('name'),
      //         e => e.$eq('aaa'),
      //       ),
      //     update => update.$pull(s =>
      //         s(
      //           f => f('spec')('pages'),
      //           q =>
      //             q(
      //               f => f('name'),
      //               e => e.$eq(this.operateName),
      //             ),
      //         ),
      //   )
      //   .then(res => {
      //     if (res.length > 0) {
      //     }
      //   });
      // uITemplates
      //   .update(
      //     filter =>
      //       filter(
      //         f => f('_id'),
      //         e => e.$eq(this.detailId),
      //       )(
      //         f => f('spec')('applicationId'),
      //         e => e.$eq(this.applicationId),
      //       )(
      //         f => f('spec')('pages')('name'),
      //         e => e.$eq('aaa'),
      //       ),
      //     update => update.$set(s => s(f => f('spec')('pages')('$'), {})),
      //   )
      //   .then(res => {
      //     if (res.length > 0) {
      //     }
      //   });
      //判断该页面是否被首页 登录页未授权页使用
      const index = Url.parse(this.uITemplateMsg?.spec.index ?? '', true).query
        .page;
      const login = this.uITemplateMsg?.spec.login
        ? Url.parse(this.uITemplateMsg?.spec.login ?? '', true).query.page
        : '';
      const authorize = this.uITemplateMsg?.spec.authorize
        ? Url.parse(this.uITemplateMsg?.spec.authorize ?? '', true).query.page
        : '';
      if (
        this.operateName === index ||
        this.operateName === login ||
        this.operateName === authorize
      ) {
        this.$message.error('该页面为首页或登录页或未授权页，不可删除');
        return;
      }
      uITemplates
        .update(
          filter =>
            filter(
              f => f('_id'),
              e => e.$eq(this.detailId),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          update =>
            update.$unset(s =>
              s(f => f('spec')('pages')(this.operateName), ''),
            ),
        )
        .then(res => {
          if (res.length > 0) {
            if (this.pageList.length === 1 && this.sortName === '未分类') {
              this.$router.push({
                query: {menu: ''},
              });
            }
            this.dialogDelPage = false;
            this.operateName = '';
            this.$message.success('已删除');
            this.updateSort();
          }
        });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //新建页面保存
  private async pageSumbit() {
    if (this.addPageText === '') {
      this.$message.error('请输入页面名称');
    } else {
      try {
        let num = 0;
        for (const key in this.uITemplateMsg?.spec.pages) {
          if (key === this.addPageText) {
            num++;
          }
        }
        if (num > 0) {
          this.$message.error('页面名称不能重复');
          return;
        }
        uITemplates
          .update(
            filter =>
              filter(
                f => f('_id'),
                e => e.$eq(this.detailId),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              ),
            update =>
              update.$set(s =>
                s(f => f('spec')('pages')(this.addPageText), {
                  setting: {},
                  subPages: [
                    {
                      name: '默认',
                      tabItem: {
                        text: '默认',
                        images: [
                          '600914214bcfb3703c64eee2',
                          '600914534bcfb3008464eee3',
                        ],
                        color: '#333333',
                        selectColor: '#11A983',
                        fontSize: 14,
                        selectFontSize: 14,
                      },
                      setting: {},
                      components: [],
                    },
                  ],
                  category: this.sortName,
                }),
              ),
          )
          .then(res => {
            if (res.length > 0) {
              this.$message.success('新增成功');
              this.dialogPage = false;
              this.addPageText = '';
              this.updateSort();
            }
          });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        console.log(e);
        this.$message.error('网络异常，请稍后重试');
      }
    }
  }
  //删除分类弹窗
  private delSortPopUps(name: string) {
    this.delSortName = name;
    this.dialogDelSort = true;
  }
  //删除分类
  delSortSumbit() {
    try {
      uITemplates
        .update(
          filter =>
            filter(
              f => f('_id'),
              e => e.$eq(this.detailId),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          update =>
            update.$pull(s =>
              s(f => f('spec')('categories'), 'value', this.delSortName),
            ),
        )
        .then(res => {
          if (res.length > 0) {
            this.$message.success('已删除');
            this.dialogDelSort = false;
            if (this.delSortName === this.$route.query.menu) {
              this.$router.push({
                query: {},
              });
            }
            this.updateSort();
            this.delSortName = '';
          }
        });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //新建分类保存
  private sortSumbit() {
    if (this.addSortText === '') {
      this.$message.error('请输入分类名称');
    } else {
      try {
        let num = 0;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.uITemplateMsg?.spec.categories!.forEach(item => {
          if (item === this.addSortText) {
            num++;
          }
        });
        if (num > 0) {
          this.$message.error('分类名称不能重复');
          return;
        }
        uITemplates
          .update(
            filter =>
              filter(
                f => f('_id'),
                e => e.$eq(this.detailId),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              ),
            update =>
              update.$push(s =>
                s(f => f('spec')('categories'), this.addSortText),
              ),
          )
          .then(res => {
            if (res.length > 0) {
              this.$message.success('新增成功');
              this.dialogSort = false;
              this.addSortText = '';
              this.updateSort();
            }
          });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        console.log(e);
        this.$message.error('网络异常，请稍后重试');
      }
    }
  }
  //编辑
  private to_edit(name: string) {
    this.$router.push({
      path:
        '/application/' +
        this.$route.params.applicationId +
        '/template/' +
        this.$route.params.templateId +
        '/pageConfig?name=' +
        name,
    });
  }
  //设为首页、登录页、授权页提交
  private homeSumbit(url: string, title: string) {
    try {
      if (title === '首页') {
        uITemplates
          .update(
            filter =>
              filter(
                f => f('_id'),
                e => e.$eq(this.detailId),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              ),
            update => update.$set(s => s(f => f('spec')('index'), url)),
          )
          .then(res => {
            if (res.length > 0) {
              this.$message.success('已保存');
            }
          });
      } else if (title === '登录页') {
        uITemplates
          .update(
            filter =>
              filter(
                f => f('_id'),
                e => e.$eq(this.detailId),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              ),
            update => update.$set(s => s(f => f('spec')('login'), url)),
          )
          .then(res => {
            if (res.length > 0) {
              this.$message.success('已保存');
            }
          });
      } else if (title === '未授权页') {
        uITemplates
          .update(
            filter =>
              filter(
                f => f('_id'),
                e => e.$eq(this.detailId),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              ),
            update => update.$set(s => s(f => f('spec')('authorize'), url)),
          )
          .then(res => {
            if (res.length > 0) {
              this.$message.success('已保存');
            }
          });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async successImgList(value: {file: string}) {
    this.formData.images.push(value.file);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async removeImgList(value: string, file: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.formData.images = file.map((v: any) => {
      return v.response.file;
    });
  }
  get images() {
    return this.formData.images.map(v => {
      return {
        uid: v,
        url: this.platformConfig.downloadUrl + v,
      };
    });
  }
  //图片上传成功
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private successCover(response: any) {
    this.formData.cover = response.file;
  }
  //获取图片上传地址
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async beforeUpload(file: any) {
    const isJPEG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    const isPG = isJPEG || isPNG; //限制图片格式为jpg / png
    if (!isPG) {
      this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
      throw new Error();
    } else {
      if (this.platformConfig) {
        const res = await uploadImg(
          this.platformConfig.channels[0].channelId,
          this.platformConfig.applicationId,
          false,
          [],
        );
        if (!res) {
          throw new Error();
        }
        this.action = res.status?.upload?.url ?? '';
        this.imgData = res.status?.upload?.data ?? {};
      } else {
        throw new Error();
      }
    }
  }
  //计算文本框的宽度
  private getWidth(str: string) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    ctx.font = 'bold 16px 微软雅黑';
    const text = ctx.measureText(str);
    return text.width ? text.width : 5;
  }
  //返回重定向路由
  mounted() {
    //监听浏览器返回键  重置路由
    if (window.history) {
      history.pushState(null, '', document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }
  }
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  }
  private goBack() {
    const applicationId = this.$route.params.applicationId;
    this.$store.state.applicationId = '';
    this.$router.replace({
      path: '/application/' + applicationId + '/template/index',
    });
  }
  private backBtn() {
    const applicationId = this.$route.params.applicationId;
    this.$store.state.applicationId = '';
    this.$router.push({
      path: '/application/' + applicationId + '/template/index',
    });
  }
}
