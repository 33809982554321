
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {vueUtils} from '@lljj/vue-json-schema-form';

@Component({
  name: 'numberText',
})
export default class extends Vue {
  @Prop()
  private rootFormData: Record<string, unknown>;
  @Prop()
  private curNodePath: string;
  private num = 0;
  created() {
    const num = vueUtils.getPathVal(this.rootFormData, this.curNodePath);
    if (typeof num === 'number') {
      this.num = num;
    }
  }
  @Watch('num')
  changeNum(val: number) {
    if (!/^[1-9]\d*|0$/.test(val.toString())) {
      this.num = 0;
    } else {
      vueUtils.setPathVal(this.rootFormData, this.curNodePath, Number(val));
    }
  }
}
