
import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
import {vueUtils} from '@lljj/vue-json-schema-form';

interface InputList {
  title: string;
  type: string;
  placeholder: string;
  field: string;
}
@Component({
  name: 'inputList',
})
export default class extends Vue {
  @Prop()
  private rootFormData: Record<string, unknown>;
  @Prop()
  private curNodePath: string;
  private radio = 'ordinary';
  private inputList: InputList = {
    title: '',
    type: '',
    placeholder: '',
    field: '',
  };
  private flag = false;
  created() {
    const inputList = vueUtils.getPathVal(
      this.rootFormData,
      this.curNodePath,
    ) as InputList;
    this.inputList = inputList;
    if (
      this.inputList.field === 'avatar' ||
      this.inputList.field === 'gender' ||
      this.inputList.field === 'phone' ||
      this.inputList.field === 'birthday' ||
      this.inputList.field === 'college' ||
      this.inputList.field === 'major' ||
      this.inputList.field === 'class'
    ) {
      this.flag = true;
    }
  }
  @Watch('inputList', {deep: true})
  changeInputList() {
    vueUtils.setPathVal(this.rootFormData, this.curNodePath, this.inputList);
  }
}
