
import {Component, Vue, Prop} from 'vue-property-decorator';
import {productLabels} from '../../../../../../../resources';
import {ObjectId} from 'bson';
import {ProductLabel} from '@/externals/MaxCI-ProductLabel-v1';

interface AllProductLabel extends ProductLabel {
  checkData: Array<string>;
}
@Component({
  name: 'selectLabels',
})
export default class extends Vue {
  @Prop()
  private labels: Array<string>;
  private radio = '标签';
  private dialogLabel = false;
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private labelsData: Array<AllProductLabel> = []; //可选择的标签数据
  private selectIndex = 0;
  private allCheck = false;
  async created() {
    //查询标签
    const productLabel = await productLabels.find(stage =>
      stage.$match(match =>
        match.$and(and => {
          and(query =>
            query(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          );
          and(query =>
            query(
              f => f('spec')('name'),
              e => e.$regex(new RegExp('商品标签:')),
            ),
          );
          return and;
        }),
      ),
    );
    if (productLabel.length > 0) {
      this.labelsData = productLabel.map((v, index) => {
        const checkData = [] as Array<string>;
        this.labels.forEach(item => {
          if (v.spec.name === `${item.split(':')[0]}:${item.split(':')[1]}`) {
            checkData.push(item);
            this.selectIndex = index;
          }
        });
        return {
          ...v,
          checkData: checkData,
        };
      });
      for (let i = 0; i < this.labelsData.length; i++) {
        if (this.labelsData[i].checkData.length > 0) {
          this.selectIndex = i;
          break;
        }
      }
      this.isAllSelect();
    }
  }
  //全选
  private allSelect() {
    if (this.allCheck) {
      this.labelsData[this.selectIndex].checkData = this.labelsData[
        this.selectIndex
      ].spec.values.map(
        v => `${this.labelsData[this.selectIndex].spec.name}:${v}`,
      );
    } else {
      this.labelsData[this.selectIndex].checkData = [];
    }
  }
  //是否全选
  private isAllSelect() {
    if (
      this.labelsData[this.selectIndex].checkData.length ===
      this.labelsData[this.selectIndex].spec.values.length
    ) {
      this.allCheck = true;
    } else {
      this.allCheck = false;
    }
  }
  //选择标签的值
  private changeVal() {
    this.isAllSelect();
  }
  //选择名称
  private changeName(index: number) {
    this.selectIndex = index;
    this.isAllSelect();
  }
  //显示弹窗
  private selectPopUps() {
    this.dialogLabel = true;
  }
  //计算选中标签的个数
  get len() {
    const labels = [] as Array<string>;
    this.labelsData.forEach(item => {
      item.checkData.forEach(valItem => {
        labels.push(valItem);
      });
    });
    return labels.length;
  }
  //保存标签
  private selectSumbit() {
    const labels = [] as Array<string>;
    this.labelsData.forEach(item => {
      item.checkData.forEach(valItem => {
        labels.push(valItem);
      });
    });
    this.$emit('changeLabels', labels);
    this.dialogLabel = false;
  }
}
