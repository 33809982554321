
import {Component, Vue, Model} from 'vue-property-decorator';

@Component({
  name: 'mallServices',
})
export default class extends Vue {
  @Model('input') value: string;
  created() {
    if (this.$store.state.mallServices) {
      this.$emit('input', this.$store.state.mallServices);
    }
  }
}
