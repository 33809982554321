
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {vueUtils} from '@lljj/vue-json-schema-form';

@Component({
  name: 'stringText',
})
export default class extends Vue {
  @Prop()
  private rootFormData: Record<string, unknown>;
  @Prop()
  private curNodePath: string;
  private text = '';
  created() {
    const text = vueUtils.getPathVal(this.rootFormData, this.curNodePath);
    if (typeof text === 'string') {
      this.text = text;
    }
  }
  @Watch('text')
  changeText(val: string) {
    if (val !== '') {
      vueUtils.setPathVal(this.rootFormData, this.curNodePath, val);
    }
  }
}
