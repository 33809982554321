
import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
  name: 'specList',
})
export default class extends Vue {
  @Prop()
  private specData: Array<{
    name: string;
    values: Array<string>;
  }>;
  @Prop()
  private type: string;
  @Prop()
  private commont: boolean;
  @Prop()
  private name: boolean;
}
