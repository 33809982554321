
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';

@Component({
  name: 'imgLoad',
})
export default class extends Vue {
  @Prop()
  private src: string;
  @Prop()
  width: string;
  @Prop()
  height: string;
  private img = '';
  async created() {
    this.img = (await this.downImg(this.src)) as string;
  }
  @Watch('src')
  private async changeSrc() {
    this.img = (await this.downImg(this.src)) as string;
  }
  private downImg(url: string) {
    return new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.open('get', url, true);
      xhr.responseType = 'blob';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      xhr.onload = (res: any) => {
        const blob = res.target.response;
        const img = document.createElement('img');
        img.src = window.URL.createObjectURL(blob);
        img.onload = () => {
          resolve(this.getBase64Image(img));
        };
        img.onerror = () => {
          reject(new Error('失败' + url));
        };
      };
      xhr.send();
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getBase64Image(img: any) {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    ctx.drawImage(img, 0, 0, img.width, img.height);
    const dataURL = canvas.toDataURL('image/png'); // 可根据图片后缀，动态变换如 image/png
    return dataURL;
  }
}
