
import {Component, Vue, Watch, Model} from 'vue-property-decorator';
import {comp} from '../../../comp';
import selectLink from '../selectLink/index.vue';

@Component({
  name: 'navigateTo',
  components: {
    ...comp,
    selectLink,
  },
})
export default class extends Vue {
  @Model('input') value: string;
  private url = '';
  async created() {
    // 数据回显
    this.url = this.value;
  }
  private changeUrl(val: string) {
    this.url = val;
  }
  @Watch('url')
  changeValue(val: string) {
    this.$emit('input', val);
  }
}
