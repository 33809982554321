
import {Component, Vue, Watch, Model} from 'vue-property-decorator';
import {getHexColor} from '../../../../../../../api/publicMethod';

@Component({
  name: 'color',
})
export default class extends Vue {
  @Model('input') value: string;
  private color = '';
  private colorHex = '';
  created() {
    this.color = this.value;
    this.colorHex = getHexColor(this.color);
  }
  @Watch('color')
  changeColor(val: string) {
    this.colorHex = getHexColor(this.color);
    this.$emit('input', val);
  }
  private clearColor() {
    this.color = '';
  }
}
