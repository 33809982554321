
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {regComp} from '../../../comp';
import color from '../../operate/color/index.vue';
import numberText from '../../operate/numberText/index.vue';
import serviceList from '../../operate/serviceList/index.vue';
import imgLoad from '../../operate/imgLoad/index.vue';
import navigateTo from '../../operate/navigateTo/index.vue';

interface ServiceData {
  img?: string;
  text?: string;
}
@Component({
  name: 'mallMineOrder',
  components: {numberText, serviceList, color, imgLoad, navigateTo},
})
export default class mallMineOrder extends Vue {
  static metadata = {
    name: '我的订单',
    value: 'mallMineOrder',
    type: '商城',
    icon: 'bill',
    schema: {
      type: 'object',
      properties: {
        setting: {
          type: 'object',
          properties: {
            navigateTo: {
              title: '跳转地址',
              type: 'string',
              'ui:widget': navigateTo,
            },
            itemList: {
              title: '服务',
              type: 'array',
              minItems: 1,
              maxItems: 12,
              default: [
                {
                  img: '600914214bcfb3703c64eee2',
                  text: '默认',
                  url: '',
                },
              ],
              items: {
                type: 'object',
                'ui:field': serviceList,
              },
            },
            style: {
              type: 'object',
              properties: {
                background: {
                  title: '背景色',
                  type: 'string',
                  'ui:widget': color,
                  default: '#ffffff',
                },
                padding: {
                  title: '页面边距',
                  type: 'array',
                  minItems: 4,
                  maxItems: 4,
                  items: {
                    type: 'number',
                    'ui:field': numberText,
                  },
                  default: [0, 0, 0, 0],
                },
                borderRadius: {
                  title: '圆角',
                  type: 'array',
                  minItems: 4,
                  maxItems: 4,
                  items: {
                    type: 'number',
                    'ui:field': numberText,
                  },
                  default: [0, 0, 0, 0],
                },
              },
            },
          },
        },
      },
    },
    uiSchema: {
      setting: {
        style: {
          padding: {
            'ui:options': {
              addable: false,
              sortable: false,
              removable: false,
              showIndexNumber: false,
            },
          },
          borderRadius: {
            'ui:options': {
              addable: false,
              sortable: false,
              removable: false,
              showIndexNumber: false,
            },
          },
        },
      },
    },
    paramData: [],
  };
  @Prop()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setting: any;
  @Prop()
  private index: Array<number>;
  @Prop()
  private borderIndex: number;
  private styles: {
    background: string;
    padding: string;
    borderRadius: string;
  } = {background: '', padding: '', borderRadius: ''};
  private serviceData: Array<ServiceData> = [];

  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  @Watch('setting', {deep: true, immediate: true})
  changeSetting(val: {
    style: {
      background: string;
      padding: Array<number>;
      borderRadius: Array<number>;
    };
    itemList?: Array<{
      img?: string;
      text?: string;
      url?: string;
    }>;
  }) {
    if (val.style) {
      if (val.style.background) {
        this.styles.background = val.style.background;
      }
      this.styles.padding =
        val.style.padding[0] +
        'px ' +
        val.style.padding[1] +
        'px ' +
        val.style.padding[2] +
        'px ' +
        val.style.padding[3] +
        'px';
      this.styles.borderRadius =
        val.style.borderRadius[0] +
        'px ' +
        val.style.borderRadius[1] +
        'px ' +
        val.style.borderRadius[2] +
        'px ' +
        val.style.borderRadius[3] +
        'px';
    }
    if (val.itemList) {
      const serviceData = [] as Array<ServiceData>;
      val.itemList.forEach(item => {
        serviceData.push({
          img: item.img,
          text: item.text,
        });
      });
      this.serviceData = serviceData;
    }
  }
  //删除组件
  private delComp() {
    this.$emit('delCompPopUps', this.index[0], this.index[1]);
  }
}

regComp({mallMineOrder});
