
import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
import {
  uITemplates,
  shops,
  products,
  couponActivitys,
  coupons,
} from '../../../../../../../resources';
import {UIPage, UISubPage} from '@/externals/MaxCI-UITemplate-v1';
import {comp} from '../../../comp';
import Url from 'url';
import {ObjectId} from 'bson';
import {EJSON} from 'bson';
import {Shop} from '@/externals/MaxCI-Shop-v1';
import {Product} from '@/externals/MaxCI-Product-v1';
import {CouponActivity} from '@/externals/MaxCI-CouponActivity-v1';
import moment from 'moment';

interface LinkData {
  sortName?: string;
  page?: Array<NameUIPage>;
}
interface NameUIPage extends UIPage {
  name: string;
}
interface ParamData {
  name?: string;
  value?: Array<ObjectId>;
  valName?: string;
  nameList?: Array<string>;
  des?: string;
  type?: string;
  dataType?: string;
}
interface CouponInfor extends CouponActivity {
  remainingCount: number;
}
@Component({
  name: 'selectLink',
  components: {
    ...comp,
  },
})
export default class extends Vue {
  @Prop()
  private value: string;
  @Prop()
  private otherUrl: string; //是否可以选择外部链接和其他小程序链接
  @Prop()
  private title: string;
  private isIndeterminate = false;
  private checkAll = false; //选择店铺、商品、优惠券 全选
  private paramIndex = 0; //当前选择参数的index
  private dialogId = false; //选择店铺商品弹窗
  private url = '';
  private direction = 'forward'; //链接方向 back、forward、redirect
  private currentPageName = ''; //选择的页面名称
  private linkName = '选择链接'; //选择内容/子页面名称
  private webLink = ''; //外部链接
  private wminiAppid = ''; //小程序appid
  private wminiLink = ''; //小程序链接
  private thumbnail = ''; //缩略图
  private paramData: Array<ParamData> = []; //填写参数
  private subPageData: Array<UISubPage> = []; //当前选中页面的子页面
  private subPageIndex = 10000; //选中子页面的index
  private pageIndex = 10000; //选中的page的idnex
  private linkMenuIndex = 0; //选择链接左侧菜单index
  private linkData: Array<LinkData> = []; //链接数据
  private dialogLink = false;
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private detailId = ObjectId.createFromHexString(
    this.$route.params.templateId,
  );
  private searchName = ''; //搜索的名称
  private selectData: Array<Shop | Product | CouponInfor> = [];
  private listParams = {
    page: 1,
    limit: 10,
  };
  private shopNameData: Array<Shop> = []; //商品列表中shopname
  private valIds: Array<string> = []; //选中的id
  async created() {
    // 数据回显
    this.assignmentUrl();
    if (this.title) {
      this.pageIndex = 0;
    }
    try {
      //获取分类
      const uITemplate = (
        await uITemplates.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.detailId),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          ),
        )
      ).find(() => true);
      if (uITemplate) {
        const linkData = [] as Array<LinkData>;
        if (uITemplate?.spec.categories) {
          uITemplate?.spec.categories.forEach(item => {
            linkData.push({sortName: item, page: []});
          });
        }
        linkData.forEach(async item => {
          const page = [] as Array<NameUIPage>;
          for (const key in uITemplate.spec.pages) {
            if (uITemplate.spec.pages[key].category === item.sortName) {
              page.push({
                name: key,
                ...uITemplate.spec.pages[key],
              });
            }
          }
          item.page = page;
        });
        this.linkData = linkData;
        if (this.otherUrl) {
          this.linkData.unshift({sortName: '其他小程序'});
          this.linkData.unshift({sortName: '外部链接'});
        }
        if (this.title) {
          this.pageIndex = 0;
          this.changePage(0, null);
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  @Watch('dialogId')
  private changeDialogId() {
    if (!this.dialogId) {
      this.searchName = '';
      this.selectData = [];
      this.listParams.page = 1;
      this.listParams.limit = 10;
    }
  }
  //全选
  private handleCheckAllChange(val: boolean) {
    this.isIndeterminate = false;
    if (!val) {
      this.valIds = [];
      this.paramData[this.paramIndex].value = [];
      this.paramData[this.paramIndex].nameList = [];
    } else {
      this.valIds = this.selectData.map(
        v => `${v._id.toHexString()},${v.spec.name}`,
      );
      this.paramData[this.paramIndex].value = this.selectData.map(v => v._id);
      this.paramData[this.paramIndex].nameList = this.selectData.map(
        v => v.spec.name,
      );
    }
  }
  //更新选中id的值
  private updateValIds() {
    const valIds: Array<string> = [];
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.paramData[this.paramIndex].value!.forEach((valItem, valIndex) => {
      valIds.push(
        `${valItem.toHexString()},${
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.paramData[this.paramIndex].nameList![valIndex]
        }`,
      );
    });
    this.valIds = valIds;
  }
  //删除选中参数的值
  private delParam(index: number) {
    this.paramData[this.paramIndex].value?.splice(index, 1);
    this.paramData[this.paramIndex].nameList?.splice(index, 1);
  }
  //选择店铺 商品 优惠券
  private changeId() {
    this.paramData[this.paramIndex].value = this.valIds.map(v =>
      ObjectId.createFromHexString(v.split(',')[0]),
    );
    this.paramData[this.paramIndex].nameList = this.valIds.map(
      v => v.split(',')[1],
    );
    if (this.valIds.length === this.selectData.length) {
      this.isIndeterminate = false;
      this.checkAll = true;
    } else {
      this.isIndeterminate = this.valIds.length > 0 ? true : false;
      this.checkAll = false;
    }
  }
  //滚动翻页
  private scrollEvent() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let el = document.querySelector('.selectid_con') as any;
    const scrollTop = el.scrollTop;
    const scrollHeight = el.scrollHeight;
    const offsetHeight = el.offsetHeight;
    if (offsetHeight + scrollTop >= scrollHeight) {
      this.listParams.page = this.listParams.page + 1;
      if (this.paramData[this.paramIndex].dataType === '店铺') {
        this.searchShop();
      } else if (this.paramData[this.paramIndex].dataType === '商品') {
        this.searchGoods();
      } else if (this.paramData[this.paramIndex].dataType === '优惠券') {
        this.searchCoupon();
      }
    }
  }
  //搜索
  private searchId() {
    this.listParams.page = 1;
    this.listParams.limit = 10;
    this.selectData = [];
    if (this.paramData[this.paramIndex].dataType === '店铺') {
      this.searchShop();
    } else if (this.paramData[this.paramIndex].dataType === '商品') {
      this.searchGoods();
    } else if (this.paramData[this.paramIndex].dataType === '优惠券') {
      this.searchCoupon();
    }
  }
  //处理剩余券
  private remainingCoupons(row: CouponInfor) {
    let count = '--' as string | number;
    if (row.spec.type === '领取') {
      if (row.spec.quantity.daily === 0) {
        count = 0;
      } else {
        const diff =
          Number(moment(row.spec.receivableDate.end).diff(moment(), 'days')) +
          1;
        if (moment().isBefore(moment(row.spec.receivableDate.end))) {
          count = diff * row.spec.quantity.daily - row.remainingCount;
        } else {
          count = 0;
        }
      }
    }
    return count;
  }
  //处理有限时间
  getExpiration(row: CouponInfor) {
    let time = null;
    if (row.spec.expiration.type === '固定日期') {
      time =
        moment(row.spec.expiration.begin).format('YYYY-MM-DD HH:mm') +
        '-' +
        moment(row.spec.expiration.end).format('YYYY-MM-DD HH:mm');
    } else if (row.spec.expiration.type === '领取当日') {
      time = '领取后当日起' + row.spec.expiration.day + '天内有效';
    } else if (row.spec.expiration.type === '领取次日') {
      time = '领取后次日起' + row.spec.expiration.day + '天内有效';
    }
    return time;
  }
  //处理活动时间
  private getActivityTime(start: Date, end: Date) {
    return (
      moment(start).format('YYYY-MM-DD HH:mm') +
      '-' +
      moment(end).format('YYYY-MM-DD HH:mm')
    );
  }
  //搜索优惠券
  private async searchCoupon() {
    if (this.searchName) {
      const list = await couponActivitys.find(stage =>
        stage
          .$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            )(
              f => f('spec')('name'),
              e => e.$regex(new RegExp(this.searchName)),
            )(
              f => f('spec')('receivableDate')('end'),
              e => e.$gt(moment().toDate()),
            );
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            ),
          ),
      );
      const couponActivity = list[0].table.map(v => {
        return {
          remainingCount: 0,
          ...v,
        };
      });
      couponActivity.forEach(item => {
        coupons
          .find(stage =>
            stage
              .$match(match => {
                match(
                  f => f('spec')('activityId'),
                  e => e.$eq(item._id),
                )(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                );
                return match;
              })
              .$facet(facet =>
                facet('summary', summaryStage =>
                  summaryStage.$group(
                    e => e.$literal(null),
                    group =>
                      group('remainingCount', e =>
                        e.$sum(sum =>
                          sum.$cond(
                            _if =>
                              _if.$and(
                                e =>
                                  e.$gte(
                                    e =>
                                      e.$fieldPath(f =>
                                        f('metadata')('creationTimestamp'),
                                      ),
                                    e =>
                                      e.$dateFromString(e =>
                                        e.$literal(
                                          moment().startOf('day').toString(),
                                        ),
                                      ),
                                  ),
                                e =>
                                  e.$lte(
                                    e =>
                                      e.$fieldPath(f =>
                                        f('metadata')('creationTimestamp'),
                                      ),
                                    e =>
                                      e.$dateFromString(e =>
                                        e.$literal(
                                          moment().endOf('day').toString(),
                                        ),
                                      ),
                                  ),
                              ),
                            _then => _then.$literal(1),
                            _else => _else.$literal(0),
                          ),
                        ),
                      ),
                  ),
                ),
              ),
          )
          .then(res => {
            if (res[0].summary.length > 0) {
              item.remainingCount = res[0].summary[0].remainingCount;
            }
          });
      });
      couponActivity.forEach(item => {
        this.selectData.push(item);
      });
    }
  }
  //搜索店铺
  private async searchShop() {
    if (this.searchName) {
      const list = await shops.find(stage =>
        stage
          .$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            )(
              f => f('spec')('name'),
              e => e.$regex(new RegExp(this.searchName)),
            );
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            ),
          ),
      );
      list[0].table.forEach(item => {
        this.selectData.push(item);
      });
    }
  }
  //获取商品名称
  private getShopName(id: ObjectId) {
    let name = '';
    this.shopNameData.forEach(item => {
      if (id.equals(item._id)) {
        name = item.spec.name;
      }
    });
    return name;
  }
  //搜索商品
  private async searchGoods() {
    if (this.searchName) {
      const list = await products.find(stage =>
        stage
          .$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            )(
              f => f('spec')('name'),
              e => e.$regex(new RegExp(this.searchName)),
            );
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            ),
          ),
      );
      let shopIdData: Array<ObjectId> = [];
      list[0].table.forEach(item => {
        shopIdData.push(item.spec.shopId);
      });
      shopIdData = Array.from(new Set(shopIdData));
      const shopData = await shops.find(stage =>
        stage.$match(match => {
          match(
            f => f('_id'),
            e => e.$in(shopIdData),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          );
          return match;
        }),
      );
      shopData.forEach(item => {
        this.shopNameData.push(item);
      });
      list[0].table.forEach(item => {
        this.selectData.push(item);
      });
    }
  }
  //选择id
  private selectId(index: number) {
    this.paramIndex = index;
    this.dialogId = true;
    this.isIndeterminate = false;
    this.updateValIds();
  }
  //删除url
  private delBtn() {
    this.url = '';
    this.linkName = '选择链接';
    if (this.title) {
      this.$emit('clearPage', this.title);
    }
  }
  // 数据回显
  private assignmentUrl() {
    this.url = this.value;
    if (this.url) {
      const url = Url.parse(this.url ?? '', true);
      if (url.protocol === 'https:') {
        this.linkName = '外部链接';
        this.webLink = this.url;
      } else if (url.protocol === 'wx:') {
        const str = this.url.match(/\/\/(\S*).self/);
        if (str) {
          this.currentPageName = (url.query.page as string) ?? '';
          this.linkName = (url.query.subpage as string) ?? '';
          this.direction = str[1];
        } else {
          this.linkName = '其他小程序';
          this.wminiAppid = url.host ?? '';
          this.wminiLink = url.path ?? '';
        }
      }
    } else {
      this.linkName = '选择链接';
      this.direction = 'forward';
    }
  }
  @Watch('direction')
  private changeDirection() {
    if (this.url) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const str = this.url.match(/\/\/(\S*).self/)![1];
      const url = this.url.replace(str, this.direction);
      this.url = url;
    }
  }
  @Watch('value')
  private changeValue() {
    // 数据回显
    this.assignmentUrl();
  }
  @Watch('url')
  private changeUrl(val: string) {
    this.$emit('changeUrl', val);
  }
  //平台配置信息
  get platformConfig() {
    return this.$store.state.pConfig;
  }
  //选择链接确定
  private selectLinkSumbit() {
    if (this.linkData[this.linkMenuIndex].sortName === '外部链接') {
      const url = Url.parse(this.webLink, true);
      if (this.webLink === '') {
        this.$message.error('请填写链接');
      } else if (url.protocol !== 'https:') {
        this.$message.error('链接必须是https开头');
      } else {
        this.url = this.webLink;
        this.linkName = '外部链接';
        this.dialogLink = false;
      }
    } else if (this.linkData[this.linkMenuIndex].sortName === '其他小程序') {
      if (this.wminiAppid === '') {
        this.$message.error('请填写小程序AppId');
      } else {
        this.url = 'wx://' + this.wminiAppid + this.wminiLink;
        this.linkName = '其他小程序';
        this.dialogLink = false;
      }
    } else {
      if (this.pageIndex === 10000) {
        this.$message.error('请选择页面');
      } else {
        if (this.subPageData.length > 0 && this.subPageIndex === 10000) {
          this.$message.error('请选择子页面');
        } else {
          const page = this.linkData[this.linkMenuIndex].page ?? [];
          let name = '';
          if (this.subPageIndex === 10000) {
            name = page[this.pageIndex].subPages[0].name;
          } else {
            name = page[this.pageIndex].subPages[this.subPageIndex].name;
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let params = {} as any;
          this.paramData.forEach(item => {
            if (item.type === 'ObjectId') {
              if (item.value?.length && item.name) {
                params[item.name] = item.value;
              }
            } else {
              if (item.valName && item.name) {
                params[item.name] = item.valName;
              }
            }
          });
          this.url =
            'wx://' +
            this.direction +
            '.self/page?page=' +
            page[this.pageIndex].name +
            '&subpage=' +
            name +
            '&params=' +
            EJSON.stringify(params);
          this.linkName = name;
          this.dialogLink = false;
          if (this.title) {
            this.$emit('homeSumbit', this.url, this.title);
          }
        }
      }
    }
  }
  //选择页面
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async changePage(index: number, params: any) {
    this.pageIndex = index;
    this.subPageIndex = 10000;
    const page = this.linkData[this.linkMenuIndex].page ?? [];
    this.thumbnail = page[index].image ?? '';
    this.subPageData = page[index].subPages;
    //获取页面参数
    let paramString = [] as Array<string>;
    const paramData = [] as Array<ParamData>;
    page[index].subPages.forEach(item => {
      item.components.forEach(compItem => {
        if (comp[compItem.type]) {
          comp[compItem.type].metadata.paramData.forEach(paramItem => {
            paramString.push(JSON.stringify(paramItem));
          });
        }
      });
    });
    paramString = Array.from(new Set(paramString));
    for (let i = 0; i < paramString.length; i++) {
      let id = params ? params[JSON.parse(paramString[i]).name] : '';
      const valName = typeof id === 'object' ? '' : id;
      const value = typeof id === 'object' ? id : [];
      let nameList = [] as Array<string>;
      if (typeof id === 'object' && id.length > 0) {
        if (JSON.parse(paramString[i]).dataType === '店铺') {
          const list = await shops.find(stage =>
            stage.$match(match => {
              match(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              )(
                f => f('_id'),
                e => e.$in(id),
              );
              return match;
            }),
          );
          nameList = list.map(v => v.spec.name);
        } else if (JSON.parse(paramString[i]).dataType === '商品') {
          const list = await products.find(stage =>
            stage.$match(match => {
              match(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              )(
                f => f('_id'),
                e => e.$in(id),
              );
              return match;
            }),
          );
          nameList = list.map(v => v.spec.name);
        } else if (JSON.parse(paramString[i]).dataType === '优惠券') {
          const list = await couponActivitys.find(stage =>
            stage.$match(match => {
              match(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              )(
                f => f('_id'),
                e => e.$in(id),
              );
              return match;
            }),
          );
          nameList = list.map(v => v.spec.name);
        }
      }
      paramData.push({
        name: JSON.parse(paramString[i]).name,
        des: JSON.parse(paramString[i]).des,
        type: JSON.parse(paramString[i]).type,
        dataType: JSON.parse(paramString[i]).dataType,
        value: value,
        valName: valName,
        nameList: nameList,
      });
    }
    this.paramData = paramData;
  }
  //切换子页面
  private changeSubPage(index: number) {
    this.subPageIndex = index;
  }
  //切换选择链接菜单
  private changeMenu(index: number) {
    this.linkMenuIndex = index;
    this.pageIndex = 0;
    this.subPageIndex = 10000;
    if (
      this.linkData.length > 0 &&
      this.linkData[index].sortName !== '外部链接' &&
      this.linkData[index].sortName !== '其他小程序'
    ) {
      this.changePage(0, null);
    }
  }
  //选择链接显示弹窗
  private selectUrl() {
    this.dialogLink = true;
    this.linkData.forEach((item, index) => {
      //判断是外部链接、其他小程序还是页面
      if (this.linkName === item.sortName) {
        this.linkMenuIndex = index;
      } else {
        item.page?.forEach((pageItem, pageIndex) => {
          //页面名称是否相同
          if (this.currentPageName === pageItem.name) {
            pageItem.subPages.forEach((subPagesItem, subPagesIndex) => {
              //子页面名称是否相同
              if (subPagesItem.name === this.linkName) {
                this.linkMenuIndex = index;
                const url = Url.parse(this.url ?? '', true);
                if (typeof url.query.params === 'string') {
                  this.changePage(pageIndex, EJSON.parse(url.query.params));
                }
                this.subPageIndex = subPagesIndex;
              }
            });
          }
        });
      }
    });
  }
}
