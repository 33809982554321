
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {regComp} from '../../../comp';
import navigateTo from '../../operate/navigateTo/index.vue';

@Component({
  name: 'agentPay',
  components: {navigateTo},
})
export default class agentPay extends Vue {
  static metadata = {
    name: '支付',
    value: 'agentPay',
    type: '跑腿',
    icon: 'bill',
    schema: {
      type: 'object',
      properties: {
        setting: {
          type: 'object',
          properties: {
            navigateTo: {
              title: '跳转地址',
              type: 'string',
              'ui:widget': navigateTo,
            },
            type: {
              title: '身份',
              type: 'string',
              enum: ['代取', '代送', '代购', '帮忙'],
              enumNames: ['帮我取', '帮我送', '代购', '万能帮'],
            },
          },
        },
      },
    },
    uiSchema: {},
    paramData: [],
  };
  @Prop()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setting: any;
  @Prop()
  private index: Array<number>;
  @Prop()
  private borderIndex: number;
  private type = '';
  @Watch('setting', {deep: true, immediate: true})
  changeSetting(val: {type?: string}) {
    if (val.type) {
      this.type = val.type;
    }
  }
  //删除组件
  private delComp() {
    this.$emit('delCompPopUps', this.index[0], this.index[1]);
  }
}

regComp({agentPay});
